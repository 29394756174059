import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import NavMenu from '../../components/widgets/nav-menu';
import style from './style.module.css';
import ProgressTrackerViewModel from '../../view-models/progress-tracker-view-model';
import Select from 'react-select';
import TechniqueJointOptions, { AssigmentSubmissionFormatTypes } from './data';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import { arraysHaveSameNumbers, getFormattedDate, getJWTToken } from '../../../utils/helpers/helpers';
import PopupModal from '../../components/widgets/popup-modal';
import editIcon from '../../../assets/icons/edit.png';
import closeIcon from '../../../assets/icons/red-close.png';
import pointIcon from '../../../assets/icons/points-start.png';
import testIcon from '../../../assets/icons/test.png';
import rightArrowIcon from '../../../assets/icons/chevron-right.png';
import addIcon from '../../../assets/icons/plus.png';
import EditTechniqueKeyPointForm from '../../components/widgets/edit-technique-keypoint-form';
import { Roles } from '../../../utils/auth/roles';
import AccessDeniedPage from '../../components/widgets/access-denied-page';
import AddEditCertificationForm from '../../components/widgets/add-edit-certification-form';
import axios from 'axios';
import { StudentData } from '../../../models/student/student-data';
import AddFitnessTest from '../../actions/add-fitness-test';
import TakeFitnessTest from '../../actions/take-fitness-test';
import { FitnessTestDetailsData } from '../../../models/progress-tracker/fitness-test-details-data';
import RecordFitnessTest from '../../actions/record-fitness-test';
import AcademyIcon from '../../../assets/icons/academy.png';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import AddCertifications from '../../actions/add-certifications';
import defaultProfilePic from '../../../assets/icons/user.png';
import CertificatePromotion from '../../actions/certificate-promotion';
import { StudentCertificateRecordData } from '../../../models/certifications/student-certificate-record-data';
import { CertificationData } from '../../../models/certifications/certification-data';
import CertifyNewStudent from '../../actions/certify-new-student';
import { DisciplineData } from '../../../models/discipline/discipline-data';
import { AcademyData } from '../../../models/academy/academy-data';
import AddCertificationEvent from '../../actions/add-certification-event';
import CertificationEventStudentInfo from '../../actions/certification-event-student-info';
import AddFeeStructureViewModel from '../../view-models/actions-view-models/add-fee-structure-view-model';
import AddFitnessTestViewModel from '../../view-models/actions-view-models/add-fitness-test-view-model';

const ProgressTracker = () => {
    const {
        state,
        setOpenTab,
        dispatch,
        AIModules,
        isView,
        isEdit,
        regularAssignment,
        addNewTechnique,
        techniqueName,
        timestamp,
        file,
        rows,
        handleCancelRegularAssignmentCreation,
        data,
        videoRef,
        url,
        isModalOpen,
        handleAIModuleClick,
        handleHomeWorkAssignmentClick,
        handleHomeassingmentclick,
        handleAddNewTechniqueClick,
        handleTechniqueViewClick,
        handleAddTimeStamp,
        handleNewTechniqueVideoUpload,
        handleTechniqueNameChange,
        handleInputChange,
        joinOneValue,
        joinTwoValue,
        weightageValue,
        handleAddKeyPointData,
        filteredKeyPointsData,
        currentTimestamp,
        handleAddNewTechniqueSubmit,
        techniquesData,
        mapClassesToOptions,
        selectedClassesId,
        handleClassInputChange,
        selectedTechniqueToView,
        activeTechniqueData,
        isTechniqueEditing,
        handleEditTechniqueBtnClick,
        handleEditTechniqueSubmit,
        handleCancelRegularAssignmentUpdate,
        handleDeleteTechniqueBtnClick,
        handleEditKeyPointModalClose,
        isEditKeyPointModalOpen,
        handleEditAddedKeyPointData,
        editingKeyPointData,
        handleEditKeyPointModalInputChange,
        handleSaveKeyPointUpdatedData,
        handleDeleteKeyPointUpdatedData,
        userState,
        isJoinOneSearchInputOnFocus,
        setIsJoinOneSearchInputOnFocus,
        isJoinTwoSearchInputOnFocus,
        setIsJoinTwoSearchInputOnFocus,
        joinOneSearchTerm,
        setJoinOneSearchTerm,
        joinTwoSearchTerm,
        setJoinTwoSearchTerm,
        handleRemoveUploadedVideo,
        handleCancelTechniqueCreation,
        addRegularAssignment,
        regularAssignmentFormData,
        isLoading,
        handleRegularAssignmentInputChange,
        handleAddRegularAssignment,
        regularAssignments,
        handleRegularAssignmentViewClick,
        isRegularAssignmentEditing,
        isRegularAssignmentViewing,
        handleUpdateRegularAssignment,
        handleRegularAssignmentFileUpload,
        handleRegularAssignmentEditClick,
        handleDeleteRegularAssignmentsClick,
        academies,
        academySelected,
        setAcademySelected,
        mapAcademyToOptions,
        selectedAcademyId,
        handleAcademyInputChange,
        certPgSec,
        setCertPgSec,
        selectDiscipline,
        setSelectDiscipline,
        handleAiHomeassingmentclick,
        addAIAssignment,
        studentsData,
        handlePromoteAllApprovedStudentsOfEvent,
        studentQuery,
        setstudentQuery,
        setAddAIAssignment,
        mapStudentsToOptions,
        selectedRegularAssignmentToView,
        isAddFitnessTestModalOpen,
        handleFitnessTestModalOpen,
        handleAddFitnessTestFormClose,
        handleToggleFitnessTestTemplatesListView,
        isAllFitnessTestsListOpen,
        fitnessTestTemplates,
        handleTakeFitnessTestModalOpen,
        isTakeFitnessTestModalOpen,
        handleTakeFitnessTestModalClose,
        selectedFitnessTest,
        isRecordFitnessTestModalOpen,
        handleRecordFitnessTestModalClose,
        selectedRecordedFitnessTest,
        handleRecordFitnessTestModalOpen,
        fitnessTestsConducted,
        activeFitnessTestListFilter,
        handleActiveFitnessTestListFilterChange,
        activeAcademySelected,
        activeDisciplineSelected,
        selectAcademyInCertification,
        selectDisciplineInCertification,
        isCertificationsFetching,
        certificationsList,
        isReorderEnabled,
        toggleReorder,
        certificationListCopy,
        handleReorder,
        isCertificationListShuffled,
        handleUpdateCertificationLevels,
        isCertificationFormOpen,
        handleCertificationFormOpen,
        handleCertificationFormClose,
        activeCertificationSelected,
        selectCertification,
        isCertificationStudentRecordsFetching,
        certificationStudentRecordsList,
        activeCertificationStudents,
        selectedStudentsOfCertificationsRecords,
        handleAddRemoveStudent,
        handleAddRemoveAllStudents,
        handlePromoteStudentLevelModalOpen,
        isPromoteStudentLevelModalOpen,
        isCertifyNewStudentModalOpen,
        handleCertifyNewStudentModalOpan,
        handleCertifyNewStudentModalClose,
        handlePromoteStudentLevelModalClose,
        isCertificationEditFormOpen,
        handleCertificationEditFormOpen,
        handleCertificationEditFormClose,
        editingCertificateId,
        availableNewStudentsToCertify,
        certificationPageMode,
        setCertificationPageMode,
        isCertificationEventsFetching,
        certificationEventsList,
        isCertificationEventFormOpen,
        handleCertificationEventFormOpen,
        allStudentsOFSelectedAcademyAndDiscipline,
        handleCertificationEventFormClose,
        allStudentCertificationRecordsOfDiscipline,
        handleCertificationEventEditFormClose,
        isCertificationEventEditFormOpen,
        editingCertificationEventId,
        handleCertificationEventEditFormOpen,
        activeLiveCertificationEvent,
        handleActiveLiveCertificationEventChange,
        activeStudentInfoFormOpen,
        handleActiveStudentInfoFormOpen,
        handleActiveStudentInfoFormClose,
    } = ProgressTrackerViewModel();

    const { isFitnessTestAdding } = AddFitnessTestViewModel({});
    

    const comingSoonPage = useMemo(() => {
        return (
            <div>
                <div className="w-full text-center h-full">
                    <div className="text-orange-500 text-2xl font-semibold my-[200px]">
                        Coming Soon...
                    </div>
                </div>
            </div>
        );
    }, []);

    const homeAssignmentsInitialPage = useMemo(() => {
        return (
            <div className="sm:px-5 pt-5 px-3">
                <h5 className="text-base font-normal">
                    Keep track of all the tests in one place, define new tests based on requirement
                    and maintain all test records.
                </h5>
                <div className="flex gap-20 mt-10">
                    <button
                        className={style.homeAssignmentButton}
                        onClick={handleAIModuleClick}
                    >
                        {' '}
                        AI Modules
                    </button>
                    <button
                        className={style.homeAssignmentButton}
                        onClick={handleHomeWorkAssignmentClick}
                    >
                        {' '}
                        Homework Assignment
                    </button>
                </div>
            </div>
        );
    }, []);

    const aiModulesPage = useMemo(() => {
        return (
            <div className="sm:p-10 p-3">
                <div className="mb-10 ">
                    <h1 className={style.homeAssignmentHeading}>AI Modules:</h1>
                    <p className="text-base font-normal">
                        {' '}
                        Keep track of all the AI modules created
                    </p>
                </div>
                <div className="border-b-2 pb-4 mb-4 mt-10">
                    <h1 className={style.homeAssignmentHeading}>Add A Technique:</h1>
                    <button
                        className={style.homeAssignmentButton}
                        onClick={handleAddNewTechniqueClick}
                    >
                        Add new Technique
                    </button>
                </div>
                <div>
                    <h1 className="text-2xl font-medium mb-4">Techniques:</h1>
                    {techniquesData?.map(data => (
                        <div
                            key={data?.id}
                            className={style.techniqueDetailContainer}
                        >
                            <div className={style.techniqueDetailName}>
                                {data?.attributes?.name}
                            </div>
                            <div className={`hidden sm:block ${style.techniqueDetailClass} `}>
                                {data?.attributes?.academies?.data?.length === 0
                                    ? '-'
                                    : data?.attributes?.academies?.data?.length === 1
                                      ? data?.attributes?.academies?.data[0]?.attributes?.name
                                      : `${
                                            data?.attributes?.academies?.data[0]?.attributes?.name
                                        } and ${
                                            data?.attributes?.academies?.data?.length - 1
                                        }+ more`}
                            </div>
                            <div className={`hidden sm:block ${style.techniqueDetailView} `}>
                                <a
                                    className="transition-all cursor-pointer text-sm font-semibold"
                                    onClick={() => handleTechniqueViewClick(data?.id)}
                                >
                                    View
                                </a>
                            </div>

                            {/* Mobile responside div */}
                            <div className="sm:hidden flex justify-between">
                                <div className={style.techniqueDetailClass}>
                                    {data?.attributes?.academies?.data?.length === 0
                                        ? '-'
                                        : data?.attributes?.academies?.data?.length === 1
                                          ? data?.attributes?.academies?.data[0]?.attributes?.name
                                          : `${
                                                data?.attributes?.academies?.data[0]?.attributes
                                                    ?.name
                                            } and ${
                                                data?.attributes?.academies?.data?.length - 1
                                            }+ more`}
                                </div>
                                <div className={style.techniqueDetailView}>
                                    <a
                                        className="hover:font-semibold transition-all cursor-pointer"
                                        onClick={() => handleTechniqueViewClick(data?.id)}
                                    >
                                        View
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }, [techniquesData]);

    const addNewTechniquePage = useMemo(() => {
        return (
            <div className="sm:p-10 p-1">
                <div className="sm:mb-10">
                    <h1 className={style.homeAssignmentHeading}>Add New Technique</h1>
                    <div>
                        <input
                            type="file"
                            name=""
                            id="uploadBtn"
                            accept="video/*"
                            className="hidden"
                            onChange={handleNewTechniqueVideoUpload}
                        />
                        <label
                            htmlFor="uploadBtn"
                            className={style.homeAssignmentButton}
                        >
                            {url ? `Change Video` : 'Record/Upload Video'}
                        </label>
                    </div>
                </div>
                <div className="sm:flex w-full justify-between mt-4 ">
                    <div className="sm:w-[48%] w-full">
                        <h1 className={style.homeAssignmentHeading}>Technique Name:</h1>
                        <input
                            type="text"
                            placeholder="Enter Technique Name"
                            className={`${style.formInput} border-[#eee] rounded-md outline-none mb-6 w-full`}
                            value={techniqueName}
                            onChange={e => handleTechniqueNameChange(e.target.value)}
                        />
                    </div>
                    <div className="sm:w-[48%] w-full">
                        <h1 className={`${style.homeAssignmentHeading} sm:text-right`}>
                            Academy Selected:
                        </h1>
                        <Select
                            isMulti
                            options={mapAcademyToOptions()}
                            className={`${style.formInput} w-full`}
                            classNamePrefix="Select Classes"
                            onChange={item =>
                                handleAcademyInputChange(item.map(({ value }) => value))
                            }
                            // value={mapAcademyToOptions().filter(option =>
                            //     selectedAcademyId.includes(option.value),
                            // )}
                        />
                    </div>
                </div>
                {url && (
                    <div className="sm:flex mt-2 gap-10 border-2 border-gray-200 p-2 rounded w-full">
                        {url ? (
                            <div className="sm:w-[400px]  sm:h-[650px] h-[400px] flex items-center justify-center bg-gray-100 relative">
                                <video
                                    src={url}
                                    ref={videoRef}
                                    controls
                                    className="w-full h-full object-contain"
                                ></video>
                                <div
                                    className="absolute top-0 cursor-pointer right-0 rounded-lg bg-white p-1 mt-1 mr-1 active:scale-90 transition-all"
                                    onClick={handleRemoveUploadedVideo}
                                >
                                    <img
                                        src={closeIcon}
                                        className="h-6 w-6 hover:scale-105 transition-all"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="sm:w-[400px] w-[85vw] sm:h-[650px] h-[400px] flex items-center justify-center bg-gray-100 text-gray-300 font-semibold text-xl">
                                No Video Uploaded
                            </div>
                        )}
                        <div className="flex-grow sm:min-w-[40%] w-full relative sm:p-2">
                            <div className="flex justify-between items-center mb-6 gap-4">
                                <div className="sm:flex hidden gap-8 justify-between items-center">
                                    <button
                                        className="w-auto px-6 py-1  rounded cursor-pointer bg-gray-500 text-white sm:font-normal sm:text-base text-base hover:bg-gray-700 text-center disabled:bg-gray-300 disabled:cursor-not-allowed"
                                        onClick={handleAddTimeStamp}
                                        disabled={!url}
                                    >
                                        Add key point
                                    </button>
                                    <div className="sm:flex hidden">
                                        Time stamp: {currentTimestamp.toFixed(2)}
                                    </div>
                                </div>

                                <button
                                    className={`sm:hidden block mt-2 ${style.homeAssignmentButton}`}
                                    onClick={handleAddTimeStamp}
                                    disabled={!url}
                                >
                                    Add key point
                                </button>
                            </div>
                            <div className="bg-gray-300 rounded-lg px-4 py-2 flex sm:flex-row flex-col gap-1 sm:items-center sm:justify-around justify-between w-full">
                                <div className="px-2">
                                    {timestamp === null ? 'N/A' : timestamp?.toFixed(2)}
                                </div>
                                <div className="relative ">
                                    <input
                                        placeholder="Join1"
                                        className={`w-full ${style.formInput} ${
                                            TechniqueJointOptions.filter(option =>
                                                option.label
                                                    .toLowerCase()
                                                    .includes(joinOneSearchTerm.toLowerCase())
                                            ).length === 0
                                                ? ' !border-red-400'
                                                : ''
                                        }`}
                                        value={joinOneSearchTerm}
                                        onFocus={() => setIsJoinOneSearchInputOnFocus(true)}
                                        onBlur={() =>
                                            setTimeout(
                                                () => setIsJoinOneSearchInputOnFocus(false),
                                                200
                                            )
                                        }
                                        onChange={e => setJoinOneSearchTerm(e.target.value)}
                                        disabled={timestamp === null}
                                    />
                                    {isJoinOneSearchInputOnFocus && (
                                        <div className={`${style.suggestionBox}`}>
                                            <ul className={style.suggestionBoxListWrapper}>
                                                {TechniqueJointOptions.filter(option =>
                                                    option.label
                                                        .toLowerCase()
                                                        .includes(joinOneSearchTerm.toLowerCase())
                                                ).map(item => (
                                                    <li
                                                        className={style.suggestionBoxListItem}
                                                        onClick={() => {
                                                            handleInputChange(item.value, 'join1');
                                                            setJoinOneSearchTerm(item.label);
                                                            setIsJoinOneSearchInputOnFocus(false);
                                                        }}
                                                        key={item.value}
                                                    >
                                                        <span>{item.label}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="relative">
                                    <input
                                        placeholder="Join2"
                                        className={`w-full ${style.formInput} ${
                                            TechniqueJointOptions.filter(option =>
                                                option.label
                                                    .toLowerCase()
                                                    .includes(joinTwoSearchTerm.toLowerCase())
                                            ).length === 0
                                                ? ' !border-red-400'
                                                : ''
                                        }`}
                                        value={joinTwoSearchTerm}
                                        onFocus={() => setIsJoinTwoSearchInputOnFocus(true)}
                                        onBlur={() =>
                                            setTimeout(
                                                () => setIsJoinTwoSearchInputOnFocus(false),
                                                200
                                            )
                                        }
                                        onChange={e => setJoinTwoSearchTerm(e.target.value)}
                                        disabled={timestamp === null}
                                    />
                                    {isJoinTwoSearchInputOnFocus && (
                                        <div className={`${style.suggestionBox}`}>
                                            <ul className={style.suggestionBoxListWrapper}>
                                                {TechniqueJointOptions.filter(option =>
                                                    option.label
                                                        .toLowerCase()
                                                        .includes(joinTwoSearchTerm.toLowerCase())
                                                ).map(item => (
                                                    <li
                                                        className={style.suggestionBoxListItem}
                                                        onClick={() => {
                                                            handleInputChange(item.value, 'join2');
                                                            setJoinTwoSearchTerm(item.label);
                                                            setIsJoinTwoSearchInputOnFocus(false);
                                                        }}
                                                        key={item.value}
                                                    >
                                                        <span>{item.label}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <input
                                    type="number"
                                    value={weightageValue ?? ''}
                                    onChange={e =>
                                        handleInputChange(parseInt(e.target.value), 'weightage')
                                    }
                                    placeholder="Weightage"
                                    className={style.formInput}
                                    disabled={timestamp === null}
                                />
                                <button
                                    className="sm:min-w-[40px] sm:min-h-[40px] rounded  sm:mt-0 mt-2 cursor-pointer bg-gray-500 text-white sm:font-normal sm:text-base text-base hover:bg-gray-700 text-center disabled:cursor-not-allowed"
                                    onClick={handleAddKeyPointData}
                                    disabled={
                                        joinOneValue === undefined ||
                                        joinTwoValue === undefined ||
                                        !weightageValue
                                    }
                                >
                                    +
                                </button>
                            </div>
                            <div className="border-2 border-gray-300 rounded-lg mt-2 w-full">
                                <div className="bg-gray-300 rounded-lg rounded-b-none py-2 grid grid-cols-9  sm:px-0 px-1">
                                    <div className="text-center font-semibold col-span-2 ">
                                        Timestamp
                                    </div>
                                    <div className="text-center font-semibold col-span-2 ">
                                        Join1
                                    </div>
                                    <div className="text-center font-semibold col-span-2 ">
                                        Join2
                                    </div>
                                    <div className="text-center font-semibold col-span-2">
                                        Weightage
                                    </div>
                                </div>
                                <div className="grid grid-cols-9  sm:max-h-[350px] max-h-[300px] py-2 overflow-y-scroll gap-y-2 thin-scrollbar">
                                    {filteredKeyPointsData.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <div className="text-center col-span-2">
                                                {filteredKeyPointsData[index - 1]?.timestamp ===
                                                data?.timestamp
                                                    ? ''
                                                    : data?.timestamp?.toFixed(2)}
                                            </div>
                                            <div className="text-center col-span-2 ">
                                                {
                                                    TechniqueJointOptions.find(
                                                        item => item.value === data.join1
                                                    )?.label
                                                }
                                            </div>
                                            <div className="text-center col-span-2">
                                                {
                                                    TechniqueJointOptions.find(
                                                        item => item.value === data.join2
                                                    )?.label
                                                }
                                            </div>
                                            <div className="text-center col-span-2">
                                                {data.weightage}
                                            </div>
                                            <div className="flex items-center justify-center">
                                                <img
                                                    src={editIcon}
                                                    className="h-5 w-5 cursor-pointer"
                                                    onClick={() =>
                                                        handleEditAddedKeyPointData(index)
                                                    }
                                                />
                                            </div>
                                            {filteredKeyPointsData[index + 1]?.timestamp ===
                                                data?.timestamp ||
                                            index + 1 === filteredKeyPointsData.length ? null : (
                                                <hr className="col-span-9" />
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {filteredKeyPointsData.length === 0 && (
                                        <div className="col-span-9 text-center py-2 text-gray-400 font-semibold">
                                            No Keypoints added!
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="sm:absolute w-full bottom-0 flex justify-between px-2 sm:mt-0 mt-4 gap-4">
                                <button
                                    className={`${style.homeAssignmentButton}`}
                                    onClick={handleCancelTechniqueCreation}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={`${style.homeAssignmentButton} disabled:cursor-not-allowed`}
                                    onClick={handleAddNewTechniqueSubmit}
                                    disabled={
                                        filteredKeyPointsData.length === 0 ||
                                        selectedAcademyId.length === 0
                                    }
                                >
                                    Save Technique
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }, [
        selectedClassesId,
        AIModules,
        regularAssignment,
        addNewTechnique,
        isView,
        url,
        file,
        techniqueName,
        isModalOpen,
        rows,
        data,
        timestamp,
        isEdit,
        filteredKeyPointsData,
        joinOneValue,
        joinTwoValue,
        weightageValue,
        currentTimestamp,
        joinOneSearchTerm,
        joinTwoSearchTerm,
        isJoinTwoSearchInputOnFocus,
        isJoinOneSearchInputOnFocus,
        addRegularAssignment,
        addAIAssignment,
    ]);

    const viewTechniquePage = useMemo(() => {
        return (
            <div className="sm:p-10 p-1">
                <div className="mb-6">
                    <h1 className={style.homeAssignmentHeading}>View Technique:</h1>
                    {isTechniqueEditing ? (
                        <div className="flex">
                            <button
                                className="sm:w-[15%] w-[20%] bg-gray-500 text-white p-1 rounded cursor-pointer hover:bg-gray-700 text-center"
                                onClick={handleDeleteTechniqueBtnClick}
                            >
                                Delete
                            </button>
                        </div>
                    ) : (
                        <div className="flex">
                            <button
                                className="sm:w-[15%] w-[20%] bg-gray-500 text-white p-1 rounded cursor-pointer hover:bg-gray-700 text-center"
                                onClick={() => handleEditTechniqueBtnClick(activeTechniqueData)}
                            >
                                Edit
                            </button>
                        </div>
                    )}
                </div>
                <div>
                    {isTechniqueEditing ? (
                        <div className="sm:flex w-full justify-between mt-4">
                            <div>
                                <h1 className={style.homeAssignmentHeading}>Technique Name:</h1>
                                <input
                                    type="text"
                                    placeholder="Enter Technique Name"
                                    className=" border-[#eee] rounded-md outline-none mb-6 w-full"
                                    value={techniqueName}
                                    onChange={e => handleTechniqueNameChange(e.target.value)}
                                />
                            </div>
                            <div>
                                <h1 className={`${style.homeAssignmentHeading} sm:text-right`}>
                                    Academies Selected:
                                </h1>
                                <Select
                                    isMulti
                                    options={mapAcademyToOptions()}
                                    className={style.formInput}
                                    classNamePrefix="Select Classes"
                                    onChange={item =>
                                        handleAcademyInputChange(item.map(({ value }) => value))
                                    }
                                    value={mapAcademyToOptions().filter(option =>
                                        selectedAcademyId.includes(option.value)
                                    )}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="sm:flex w-full justify-between mt-4">
                            <h1 className="sm:text-xl font-medium text-lg mb-2">
                                Technique Name: {activeTechniqueData?.attributes?.name}
                            </h1>
                            <h1 className="sm:text-xl font-medium text-lg mb-2">
                                Academies:{' '}
                                {activeTechniqueData?.attributes.academies.data
                                    ?.map(data => data?.attributes?.name)
                                    .join(',')}
                            </h1>
                        </div>
                    )}
                    <div className="sm:flex sm:mt-2 mt-8 gap-10">
                        <div className="sm:w-[400px] w-full sm:h-[600px] h-[350px] flex items-center justify-center bg-gray-100">
                            <video
                                src={activeTechniqueData?.attributes?.video?.data?.attributes?.url}
                                controls
                                className="w-full h-full object-contain"
                            ></video>
                        </div>

                        <div className="sm:w-[60%] w-full relative p-2">
                            <div className="border-2 border-gray-300 rounded-lg mt-2 w-full">
                                <div className="bg-gray-300 rounded-lg rounded-b-none py-2 grid grid-cols-4">
                                    <div className="text-center font-semibold">Timestamp</div>
                                    <div className="text-center font-semibold">Join1</div>
                                    <div className="text-center font-semibold">Join2</div>
                                    <div className="text-center font-semibold">Weightage</div>
                                </div>
                                <div className="grid grid-cols-4 max-h-[350px] py-2 overflow-y-scroll gap-y-2 thin-scrollbar">
                                    {filteredKeyPointsData.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <div className="text-center">
                                                {filteredKeyPointsData[index - 1]?.timestamp ===
                                                data?.timestamp
                                                    ? ''
                                                    : data?.timestamp?.toFixed(2)}
                                            </div>
                                            <div className="text-center">
                                                {
                                                    TechniqueJointOptions.find(
                                                        item => item.value === data.join1
                                                    )?.label
                                                }
                                            </div>
                                            <div className="text-center">
                                                {
                                                    TechniqueJointOptions.find(
                                                        item => item.value === data.join2
                                                    )?.label
                                                }
                                            </div>
                                            <div className="text-center">{data.weightage}</div>
                                            {filteredKeyPointsData[index + 1]?.timestamp ===
                                                data?.timestamp ||
                                            index + 1 === filteredKeyPointsData.length ? null : (
                                                <hr className="col-span-4" />
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {filteredKeyPointsData.length === 0 && (
                                        <div className="col-span-4 text-center py-2 text-gray-400 font-semibold">
                                            No Keypoints added!
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="sm:absolute w-full bottom-0 sm:mt-0 mt-4">
                                {isTechniqueEditing && (
                                    <button
                                        className="justify-start px-10 py-1 bg-gray-500 text-white rounded cursor-pointer hover:bg-gray-700 text-center disabled:cursor-not-allowed"
                                        onClick={handleEditTechniqueSubmit}
                                        // disabled={
                                        //     activeTechniqueData?.attributes
                                        //         ?.name === techniqueName
                                        //         // &&
                                        //     // arraysHaveSameNumbers(
                                        //     //     selectedAcademyId,
                                        //     //     activeTechniqueData?.attributes?.academies?.data?.map(
                                        //     //         item => item.id,
                                        //     //     ),
                                        //     // )
                                        // }
                                    >
                                        Save Changes
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [
        filteredKeyPointsData,
        isTechniqueEditing,
        techniqueName,
        selectedClassesId,
        selectedAcademyId,
    ]);

    const viewRegularAssignmentsPage = useMemo(() => {
        return (
            <div className="sm:p-10 p-1">
                <div className="sm:mb-10">
                    <h1 className={style.homeAssignmentHeading}>Viewing Assignment</h1>
                    {/* {isRegularAssignmentEditing ? (
                        <div className="flex">
                            <button
                                className="sm:w-[15%] w-[20%] bg-gray-500 text-white p-1 rounded cursor-pointer hover:bg-gray-700 text-center"
                                onClick={handleDeleteRegularAssignmentsClick}
                            >
                                Delete
                            </button>
                        </div>
                    ) : (
                        <div className="flex">
                            <button
                                className="sm:w-[15%] w-[20%] bg-gray-500 text-white p-1 rounded cursor-pointer hover:bg-gray-700 text-center"
                                onClick={handleRegularAssignmentEditClick}
                            >
                                Edit
                            </button>
                        </div>
                    )} */}
                </div>
                <div className="sm:flex w-full justify-between mt-4 ">
                    <div className="w-full">
                        <h1 className={style.homeAssignmentHeading}>Assignment Name:</h1>
                        <input
                            type="text"
                            placeholder="Enter Technique Name"
                            className={`${style.formInput} border-[#eee] rounded-md outline-none mb-6 w-full`}
                            value={regularAssignmentFormData.name}
                            onChange={e =>
                                handleRegularAssignmentInputChange('name', e.target.value)
                            }
                            disabled={!isRegularAssignmentEditing}
                        />
                    </div>
                </div>
                <div>
                    <div className='flex flex-col sm:flex-row sm:items-center sm:gap-4 mb-1'>
                        <h1 className={style.homeAssignmentHeading}>Accept submissions?</h1>
                        <div className='h-[40px] w-[240px] p-1 flex items-center bg-gray-300 rounded relative'>
                            <div className={`w-1/2 h-full flex transition-all items-center justify-center rounded ${regularAssignmentFormData.allowLateSubmission ? 'bg-white' : ''}`}>Yes</div>
                            <div className={`w-1/2 h-full flex transition-all items-center justify-center rounded ${regularAssignmentFormData.allowLateSubmission ? '' : 'bg-white'}`}>No</div>
                            <input 
                                type='checkbox'
                                className='absolute w-full h-full opacity-0 disabled:cursor-not-allowed'
                                checked={regularAssignmentFormData.allowLateSubmission}
                                required={regularAssignmentFormData.allowLateSubmission ? true : false}
                                onChange={e => handleRegularAssignmentInputChange('allowLateSubmission', e.target.checked)}
                                disabled={selectedRegularAssignmentToView?.attributes?.acceptSubmission === true}
                            />
                        </div>
                    </div>
                    <div className={`bg-white border-slate-200 ${regularAssignmentFormData.allowLateSubmission ? 'p-2 border-2' : ''} rounded transition-all`}>
                        <div
                            className={`${
                                    regularAssignmentFormData.allowLateSubmission ? 'h-auto opacity-100 mt-2' : 'h-0 opacity-0 m-0'
                                } overflow-hidden transition-all duration-300 ease-in-out w-full flex flex-col gap-1`}
                        >
                            <div className='flex items-center gap-2 p-1 px-4 justify-between bg-slate-50 rounded'>
                                <div>Submission Type</div>
                                <select 
                                    className='w-[70%] rounded-md border-gray-200 py-1 px-3 disabled:bg-slate-100' 
                                    required={regularAssignmentFormData.allowLateSubmission ? true : false}
                                    value={regularAssignmentFormData?.submissionType ?? ''}
                                    onChange={e => handleRegularAssignmentInputChange('submissionType', e.target.value)}
                                    disabled={selectedRegularAssignmentToView?.attributes?.acceptSubmission === true}
                                >
                                    <option value=''></option>
                                    {AssigmentSubmissionFormatTypes.map(type => (
                                        <option key={type.value} value={type.value}>{type.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex items-center gap-2 p-1 px-4 justify-between bg-slate-50 rounded'>
                                <div>Deadline</div>
                                <input 
                                    type='date'
                                    className='w-[70%] rounded-md border-gray-200 py-1 px-3' 
                                    required={regularAssignmentFormData.allowLateSubmission ? true : false}
                                    value={regularAssignmentFormData?.submissionDeadline ?? ''}
                                    onChange={e => handleRegularAssignmentInputChange('submissionDeadline', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex w-full justify-between mt-4">
                    <div className="sm:w-[15%] w-full">
                        <h1 className={`${style.homeAssignmentHeading}`}>
                            Assign To:
                        </h1>
                        <select
                            required
                            value={regularAssignmentFormData.assignTo || ''}
                            onChange={e =>
                                handleRegularAssignmentInputChange(
                                    'assignTo',
                                    e.target.value
                                )
                            }
                            className={`${style.formInput} w-full mb-6`}
                            disabled
                        >
                            <option value='none'></option>
                            <option value="all">All</option>
                            <option value="classes">Classes</option>
                            <option value="students">Students</option>
                        </select>
                    </div>
                    {regularAssignmentFormData.assignTo === 'classes' && (
                         <div className="sm:w-[80%] w-full">
                            <h1 className={`${style.homeAssignmentHeading} sm:text-right`}>
                                Classes Selected:
                            </h1>
                            <Select
                                isMulti
                                options={mapClassesToOptions()}
                                className={`${style.formInput} w-full`}
                                classNamePrefix="Select Classes"
                                isClearable={mapClassesToOptions().some((option) => !selectedRegularAssignmentToView?.attributes?.forClasses?.data?.some(item => item.id === option.value))}
                                onChange={(item, { action }) => {
                                    if (action === 'clear') {
                                        handleRegularAssignmentInputChange(
                                            'classes',
                                            selectedRegularAssignmentToView?.attributes?.forClasses?.data.map(item => item.id) ?? []
                                        );
                                    }else{
                                        handleRegularAssignmentInputChange(
                                            'classes',
                                            item.map(({ value }) => value)
                                        )
                                    }
                                }}
                                value={mapClassesToOptions().filter(option =>
                                    regularAssignmentFormData.classes?.includes(option.value)
                                )}
                                isDisabled={!isRegularAssignmentEditing}
                                styles={{
                                    multiValue: (styles, { data }) => {
                                        return selectedRegularAssignmentToView?.attributes?.forClasses?.data?.some(item => item.id === data.value)
                                            ? {
                                                ...styles,
                                                backgroundColor: 'lightgray',
                                                fontWeight: 'bold',
                                            }
                                            : styles;
                                    },
                                    multiValueLabel: (styles, { data }) => ({
                                        ...styles,
                                        paddingRight: selectedRegularAssignmentToView?.attributes?.forClasses?.data?.some(item => item.id === data.value) ? 6 : 0,
                                    }),
                                    multiValueRemove: (styles, { data }) => ({
                                        ...styles,
                                        display: selectedRegularAssignmentToView?.attributes?.forClasses?.data?.some(item => item.id === data.value) ? 'none' : 'block',
                                    }),
                                }}
                            />
                        </div>
                    )}
                    {regularAssignmentFormData.assignTo === 'students' && (
                         <div className="sm:w-[80%] w-full">
                            <h1 className={`${style.homeAssignmentHeading} sm:text-right`}>
                                Students Selected:
                            </h1>
                            <Select
                                isMulti
                                options={mapStudentsToOptions()}
                                className={`${style.formInput} w-full`}
                                isClearable={true}
                                classNamePrefix="Select Students"
                                onChange={(item, { action }) => {
                                    if (action === 'clear') {
                                        handleRegularAssignmentInputChange(
                                            'students',
                                            selectedRegularAssignmentToView?.attributes?.forStudents?.data.map(item => item.id) ?? []
                                        );
                                    }else{
                                        handleRegularAssignmentInputChange(
                                            'students',
                                            item.map(({ value }) => value)
                                        );
                                    }
                                }}
                                value={mapStudentsToOptions().filter(option => regularAssignmentFormData.students?.includes(option.value))}
                                isDisabled={!isRegularAssignmentEditing}
                                styles={{
                                    multiValue: (styles, { data }) => {
                                        return selectedRegularAssignmentToView?.attributes?.forStudents?.data?.some(item => item.id === data.value)
                                            ? {
                                                ...styles,
                                                backgroundColor: 'lightgray',
                                                fontWeight: 'bold',
                                            }
                                            : styles;
                                    },
                                    multiValueLabel: (styles, { data }) => ({
                                        ...styles,
                                        paddingRight: selectedRegularAssignmentToView?.attributes?.forStudents?.data?.some(item => item.id === data.value) ? 6 : 0,
                                    }),
                                    multiValueRemove: (styles, { data }) => ({
                                        ...styles,
                                        display: selectedRegularAssignmentToView?.attributes?.forStudents?.data?.some(item => item.id === data.value) ? 'none' : 'block',
                                    }),
                                }}
                            />
                        </div>
                    )}
                </div>
                <div>
                    <div className="w-full">
                        <h1 className={style.homeAssignmentHeading}>Description:</h1>
                        <textarea
                            placeholder="Mention steps to complete assignment"
                            className={`${style.formInput} border-[#eee] rounded-md outline-none mb-6 w-full thin-scrollbar`}
                            value={regularAssignmentFormData.description}
                            rows={12}
                            onChange={e =>
                                handleRegularAssignmentInputChange('description', e.target.value)
                            }
                            disabled={!isRegularAssignmentEditing}
                        />
                    </div>
                </div>
                <div className="flex w-full items-center p-4 bg-slate-100 rounded">
                    <div className="flex-grow">
                        <input
                            type="file"
                            name=""
                            id="uploadBtn"
                            accept="image/*,video/*,audio/*,.pdf"
                            className="hidden"
                            onChange={handleRegularAssignmentFileUpload}
                        />
                        <label
                            htmlFor="uploadBtn"
                            className={style.homeAssignmentButton}
                        >
                            Upload a File
                        </label>
                    </div>
                    <div className="font-semibold text-slate-400">
                        {regularAssignmentFormData.fileId === null ? (
                            `No File Uploaded`
                        ) : (
                            <a
                                href={regularAssignmentFormData.fileUrl}
                                className="text-blue-400"
                                rel="noreferrer"
                                target="_blank"
                            >
                                View Uploaded File
                            </a>
                        )}
                    </div>
                </div>
                <div className="w-full bottom-0 flex justify-between px-2 mt-4 gap-4">
                    <button
                        className={`${style.homeAssignmentButton}`}
                        onClick={handleCancelRegularAssignmentUpdate}
                    >
                        Cancel
                    </button>
                        {/* <button
                            className={`${style.homeAssignmentButton} disabled:cursor-not-allowed`}
                            onClick={handleUpdateRegularAssignment}
                            disabled={
                                ((regularAssignmentFormData.assignTo === 'none') || 
                                    (regularAssignmentFormData.assignTo === 'classes' && regularAssignmentFormData.classes?.length === 0) ||
                                    (regularAssignmentFormData.assignTo === 'students' && regularAssignmentFormData.students?.length === 0)
                                ) ||
                                regularAssignmentFormData.name === '' ||
                                regularAssignmentFormData.description === ''
                            }
                        >
                            Update Assignment
                        </button> */}
                </div>
            </div>
        );
    }, [isRegularAssignmentViewing, isRegularAssignmentEditing, regularAssignmentFormData]);

    const regularAssignmentPage = useMemo(() => {
        return (
            <div className="sm:p-10 p-3">
                <div className="mb-10 ">
                    <h1 className={style.homeAssignmentHeading}>Homework Assignment:</h1>
                    <p className="text-base font-normal">
                        {' '}
                        Keep track of all the Homework Assignments created
                    </p>
                </div>
                <div className="border-b-2 pb-4 mb-4 mt-10">
                    <h1 className={style.homeAssignmentHeading}>Add a New Assignment:</h1>
                    <button
                        className={style.homeAssignmentButton}
                        onClick={handleHomeassingmentclick}
                    >
                        Regular assignment
                    </button>{' '}
                    <button
                        className={style.homeAssignmentButton}
                        onClick={handleAiHomeassingmentclick}
                    >
                        AI assignment
                    </button>
                </div>
                <div>
                    <h1 className="text-2xl font-medium mb-4">Assignments:</h1>
                    {regularAssignments?.map(data => (
                        <div
                            key={data?.id}
                            className={style.techniqueDetailContainer}
                        >
                            <div className='grid grid-cols-1 sm:grid-cols-2 flex-grow'>
                                <div className={style.techniqueDetailName}>
                                    {data?.attributes?.name}
                                    <div className='text-xs rounded-xl px-2 bg-slate-200'>{getFormattedDate(new Date(data?.attributes?.createdAt))}</div>
                                </div>
                                <div className={`${style.techniqueDetailClass}`}>
                                    {data?.attributes?.type === 'Regular' && (
                                        data?.attributes?.forAll?.data !== null ? (
                                            'All Students'
                                        ) : data?.attributes?.forClasses?.data?.length > 0 ? (
                                            `${data?.attributes?.forClasses?.data[0]?.attributes?.class_name}${data?.attributes?.forClasses?.data?.length > 1 ? ` and ${data?.attributes?.forClasses?.data?.length - 1} ${data?.attributes?.forClasses?.data?.length > 2 ? 'classes' : 'class'} more` : ''}`

                                        ) : (
                                            `${data?.attributes?.forStudents?.data[0]?.attributes?.firstName}${data?.attributes?.forStudents?.data?.length > 1 ? `and ${data?.attributes?.forStudents?.data?.length - 1} ${data?.attributes?.forStudents?.data?.length > 2 ? 'students' : 'student'} more` : ''}`
                                       )
                                    )}
                                </div>
                            </div>
                            <div className={`${style.techniqueDetailView} `}>
                                <a
                                    className="transition-all cursor-pointer font-semibold text-sm"
                                    onClick={() => handleRegularAssignmentViewClick(data?.id)}
                                >
                                    View
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }, [regularAssignments]);

    const addRegularAssignmentPage = useMemo(() => {
        return (
            <div className="sm:p-10 p-1">
                <div className="sm:mb-10">
                    <h1 className={style.homeAssignmentHeading}>Add New Assignment</h1>
                    <div className="flex w-full sm:items-center flex-col sm:flex-row gap-4">
                        <div className="flex-grow">
                            <input
                                type="file"
                                name=""
                                id="uploadBtn"
                                accept="image/*,video/*,audio/*,.pdf"
                                className="hidden"
                                onChange={handleRegularAssignmentFileUpload}
                            />
                            <label
                                htmlFor="uploadBtn"
                                className={style.homeAssignmentButton}
                            >
                                Upload a File
                            </label>
                        </div>
                        <div className="font-semibold text-slate-400 text-center ">
                            {regularAssignmentFormData.fileId === null ? (
                                `No File Uploaded`
                            ) : (
                                <a
                                    href={regularAssignmentFormData.fileUrl}
                                    className="text-blue-400"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    View Uploaded File
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <div className="sm:flex w-full justify-between mt-4 ">
                    <div className="w-full">
                        <h1 className={style.homeAssignmentHeading}>Assignment Name:</h1>
                        <input
                            type="text"
                            placeholder="Enter Technique Name"
                            className={`${style.formInput} border-[#eee] rounded-md outline-none mb-6 w-full`}
                            value={regularAssignmentFormData.name}
                            onChange={e =>
                                handleRegularAssignmentInputChange('name', e.target.value)
                            }
                        />
                        
                    </div>
                </div>
                <div>
                    <div className='flex flex-col sm:flex-row sm:items-center sm:gap-4 mb-1'>
                        <h1 className={style.homeAssignmentHeading}>Accept submissions?</h1>
                        <div className='h-[40px] w-[240px] p-1 flex items-center bg-gray-300 rounded relative'>
                            <div className={`w-1/2 h-full flex transition-all items-center justify-center rounded ${regularAssignmentFormData.allowLateSubmission ? 'bg-white' : ''}`}>Yes</div>
                            <div className={`w-1/2 h-full flex transition-all items-center justify-center rounded ${regularAssignmentFormData.allowLateSubmission ? '' : 'bg-white'}`}>No</div>
                            <input 
                                type='checkbox'
                                className='absolute w-full h-full opacity-0'
                                checked={regularAssignmentFormData.allowLateSubmission}
                                required={regularAssignmentFormData.allowLateSubmission ? true : false}
                                onChange={e => handleRegularAssignmentInputChange('allowLateSubmission', e.target.checked)}
                            />
                        </div>
                    </div>
                    <div className={`bg-white border-slate-200 ${regularAssignmentFormData.allowLateSubmission ? 'p-2 border-2' : ''} rounded transition-all`}>
                        <div
                            className={`${
                                    regularAssignmentFormData.allowLateSubmission ? 'h-auto opacity-100 mt-2' : 'h-0 opacity-0 m-0'
                                } overflow-hidden transition-all duration-300 ease-in-out w-full flex flex-col gap-1`}
                        >
                            <div className='flex items-center gap-2 p-1 px-4 justify-between bg-slate-50 rounded'>
                                <div>Submission Type</div>
                                <select 
                                    className='w-[70%] rounded-md border-gray-200 py-1 px-3' 
                                    required={regularAssignmentFormData.allowLateSubmission ? true : false}
                                    value={regularAssignmentFormData?.submissionType ?? ''}
                                    onChange={e => handleRegularAssignmentInputChange('submissionType', e.target.value)}
                                >
                                    <option value=''></option>
                                    {AssigmentSubmissionFormatTypes.map(type => (
                                        <option key={type.value} value={type.value}>{type.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex items-center gap-2 p-1 px-4 justify-between bg-slate-50 rounded'>
                                <div>Deadline</div>
                                <input 
                                    type='date'
                                    className='w-[70%] rounded-md border-gray-200 py-1 px-3' 
                                    required={regularAssignmentFormData.allowLateSubmission ? true : false}
                                    value={regularAssignmentFormData?.submissionDeadline ?? ''}
                                    onChange={e => handleRegularAssignmentInputChange('submissionDeadline', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex w-full justify-between mt-4 ">
                    <div className="sm:w-[15%] w-full">
                        <h1 className={`${style.homeAssignmentHeading}`}>
                            Assign To:
                        </h1>
                        <select
                            required
                            value={regularAssignmentFormData.assignTo || ''}
                            onChange={e =>
                                handleRegularAssignmentInputChange(
                                    'assignTo',
                                    e.target.value
                                )
                            }
                            className={`${style.formInput} w-full`}
                        >
                            <option value='none'></option>
                            <option value="all">All</option>
                            <option value="classes">Classes</option>
                            <option value="students">Students</option>
                        </select>
                    </div>
                    {regularAssignmentFormData.assignTo === 'students' && (
                        <div className="sm:w-[80%] w-full">
                            <h1 className={`${style.homeAssignmentHeading} sm:text-right`}>
                                Students Selected:
                            </h1>
                            <Select
                                isMulti
                                required
                                options={mapStudentsToOptions()}
                                className={`${style.formInput} w-full`}
                                classNamePrefix="Select Students"
                                onChange={item =>
                                    handleRegularAssignmentInputChange(
                                        'students',
                                        item.map(({ value }) => value)
                                    )
                                }
                                value={mapStudentsToOptions().filter(option =>
                                    regularAssignmentFormData.students?.includes(option.value)
                                )}
                            />
                        </div>
                    )}
                    {regularAssignmentFormData.assignTo === 'classes' && (
                        <div className="sm:w-[80%] w-full">
                            <h1 className={`${style.homeAssignmentHeading} sm:text-right`}>
                                Classes Selected:
                            </h1>
                            <Select
                                isMulti
                                required
                                options={mapClassesToOptions()}
                                className={`${style.formInput} w-full`}
                                classNamePrefix="Select Classes"
                                onChange={item =>
                                    handleRegularAssignmentInputChange(
                                        'classes',
                                        item.map(({ value }) => value)
                                    )
                                }
                                value={mapClassesToOptions().filter(option =>
                                    regularAssignmentFormData.classes?.includes(option.value)
                                )}
                            />
                        </div>
                    )}
                </div>
                <div className='mt-8'>
                    <div className="w-full">
                        <h1 className={style.homeAssignmentHeading}>Instructions:</h1>
                        <textarea
                            placeholder="Mention steps to complete assignment"
                            className={`${style.formInput} border-[#eee] rounded-md outline-none mb-6 w-full thin-scrollbar`}
                            value={regularAssignmentFormData.description}
                            rows={12}
                            onChange={e =>
                                handleRegularAssignmentInputChange('description', e.target.value)
                            }
                        />
                    </div>
                </div>
                <div className="w-full bottom-0 flex justify-between px-2 mt-4 gap-4">
                    <button
                        className={`${style.homeAssignmentButton}`}
                        onClick={handleCancelRegularAssignmentCreation}
                    >
                        Cancel
                    </button>
                    <button
                        className={`${style.homeAssignmentButton} disabled:cursor-not-allowed`}
                        onClick={handleAddRegularAssignment}
                        disabled={
                            ((regularAssignmentFormData.assignTo === 'none') || 
                                (regularAssignmentFormData.assignTo === 'classes' && regularAssignmentFormData.classes.length === 0) ||
                                (regularAssignmentFormData.assignTo === 'students' && regularAssignmentFormData.students.length === 0)
                            ) ||
                            regularAssignmentFormData.name === '' ||
                            regularAssignmentFormData.description === ''
                        }
                    >
                        Save assignment
                    </button>
                </div>
            </div>
        );
    }, [regularAssignmentFormData]);


    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [selectedStudents, setSelectedStudents] = useState<StudentData[]>([]);
    const [isFocused, setIsFocused] = useState(false);
    const [description, setdescription] = useState('');

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedId(parseInt(event.target.value));
    };

    const handleStudentQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setstudentQuery(event.target.value);
    };


    const handleStudentSelect = (student: StudentData) => {
        setSelectedStudents([...selectedStudents, student]);
        setstudentQuery('');
        setIsFocused(false);
    };
    const handleRemoveStudent = (id: number) => {
        setSelectedStudents(selectedStudents.filter(student => student.id !== id));
    };

    const handleSubmitTechnqiueAssignment = async () => {
        if (techniquesData) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/regular-assignments`,
                    {
                        data: {
                            name:
                                selectedId !== null
                                    ? techniquesData.find(item => item.id === selectedId)
                                          ?.attributes.name
                                    : '',
                            description,
                            classes: [],
                            file: [],
                            users: userState?.user?.id ?? 0,
                            students: selectedStudents.map(student => student.id),
                            type: 'Ai',
                            technique: selectedId,
                            Status: 'not submitted',
                        },
                    },
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${getJWTToken()}`,
                        },
                    }
                );
                if (response.data) {
                    // console.log('Technique added successfully:', response.data);
                    setdescription('');
                    setSelectedId(null);
                    setSelectedStudents([]);
                    setAddAIAssignment(false);
                    alert('Assignment Sent');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };


    const addAiAssignmentPage = useMemo(() => {
        return (
            academies && (
                <div className="w-[90vw] sm:w-full flex flex-col">
                    <div className="w-full flex gap-2 flex-col sm:flex-row mx-4">
                        {techniquesData && (
                            <div className="flex-1 bg-[#0000005e]">
                                <label
                                    htmlFor="technique-select"
                                    className="block text-sm font-medium text-gray-700 ml-4"
                                >
                                    Select Technique
                                </label>
                                <select
                                    id="technique-select"
                                    value={selectedId !== null ? selectedId : ''}
                                    onChange={handleSelectChange}
                                    className="mt-1 block w-[calc(100%-2em)] py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm m-4"
                                >
                                    <option
                                        value={''}
                                        className="w-[calc(100%-2em)]"
                                    >
                                        {''}
                                    </option>
                                    {techniquesData.map(item => (
                                        <option
                                            key={item.id}
                                            value={item.id}
                                            className="w-[calc(100%-2em)]"
                                        >
                                            {item.attributes.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="flex-1 bg-[#0000005e] relative">
                            <label
                                htmlFor="student-search"
                                className="block text-sm font-medium text-gray-700 ml-4"
                            >
                                Search Student
                            </label>
                            <input
                                id="student-search"
                                type="text"
                                value={studentQuery}
                                onChange={handleStudentQueryChange}
                                onFocus={() => setIsFocused(true)}
                                className="mt-1 block w-[calc(100%-2em)] py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm m-4"
                            />
                            {isFocused && studentQuery && studentsData && (
                                <div className="absolute top-full mt-2 w-[calc(100%-2em)] bg-white border border-gray-300 rounded-md shadow-lg z-10">
                                    {studentsData
                                        .filter(
                                            student =>
                                                !selectedStudents.some(
                                                    selected => selected.id === student.id
                                                )
                                        )
                                        .map(student => (
                                            <div
                                                key={student.id}
                                                className="p-2 cursor-pointer hover:bg-gray-200 w-[calc(100%-2em)]"
                                                onClick={() => handleStudentSelect(student)}
                                            >
                                                {`${student.attributes.firstName || ''} ${student.attributes.middleName || ''} ${student.attributes.lastName || ''}`}
                                            </div>
                                        ))}
                                </div>
                            )}
                            <div className="w-full">
                                {selectedStudents.length > 0 && (
                                    <div className="text-center bg-slate-300 flex flex-col m-2 p-2 gap-2">
                                        {selectedStudents.map(student => (
                                            <div
                                                key={student.id}
                                                className="bg-slate-100 flex justify-between items-center p-2"
                                            >
                                                <span>
                                                    {`${student.attributes.firstName || ''} ${student.attributes.middleName || ''} ${student.attributes.lastName || ''}`}
                                                </span>
                                                <button
                                                    onClick={() => handleRemoveStudent(student.id)}
                                                    className="text-[#fff] bg-red-500 rounded-full w-4 h-4 flex items-center justify-center text-lg hover:bg-red-300"
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex-1 bg-[#0000005e]">
                            <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-700 ml-4"
                            >
                                Description
                            </label>
                            <textarea
                                id="description"
                                value={description}
                                onChange={event => setdescription(event.target.value)}
                                placeholder="Enter additional details"
                                className="mt-1 block w-[calc(100%-3em)] py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm m-4 h-[calc(100%-2.5em)]"
                            />
                        </div>
                    </div>
                    <div className="w-full flex justify-end mt-4">
                        <button
                            onClick={handleSubmitTechnqiueAssignment}
                            className=" py-2 px-4 rounded-md shadow-sm border-2 border-black hover:bg-[#c2c0c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            )
        );
    }, [
        academies,
        selectedId,
        studentQuery,
        isFocused,
        studentsData,
        selectedStudents,
        description,
        setdescription,
    ]);

    const renderHomeAssignmentsTab = useMemo(() => {
        return (
            <div>
                {selectedTechniqueToView === 0 &&
                    !AIModules &&
                    !regularAssignment &&
                    !addRegularAssignment &&
                    !isRegularAssignmentViewing &&
                    !addAIAssignment &&
                    homeAssignmentsInitialPage}

                {
                    AIModules &&
                        !addNewTechnique &&
                        selectedTechniqueToView === 0 &&
                        !isRegularAssignmentViewing &&
                        aiModulesPage
                }

                {addNewTechnique && addNewTechniquePage}

                {selectedTechniqueToView !== 0 && viewTechniquePage}

                {regularAssignment && regularAssignmentPage}

                {addRegularAssignment && addRegularAssignmentPage}

                {addAIAssignment && addAiAssignmentPage}

                {isRegularAssignmentViewing && viewRegularAssignmentsPage}
            </div>
        );
    }, [
        AIModules,
        isLoading,
        isRegularAssignmentViewing,
        addRegularAssignment,
        regularAssignment,
        addNewTechnique,
        isView,
        regularAssignments,
        url,
        file,
        techniqueName,
        isModalOpen,
        rows,
        regularAssignmentFormData,
        data,
        timestamp,
        isEdit,
        filteredKeyPointsData,
        joinOneValue,
        joinTwoValue,
        weightageValue,
        currentTimestamp,
        selectedClassesId,
        selectedTechniqueToView,
        isRegularAssignmentEditing,
        isTechniqueEditing,
        joinOneSearchTerm,
        joinTwoSearchTerm,
        isJoinTwoSearchInputOnFocus,
        isJoinOneSearchInputOnFocus,
        certPgSec,
        academySelected,
        selectedAcademyId,
        academies,
        selectedId,
        studentQuery,
        isFocused,
        studentsData,
        selectedStudents,
        description,
        setdescription,
    ]);

    const renderFitnessTabDependencies = [
        isAddFitnessTestModalOpen,
        isTakeFitnessTestModalOpen,
        isAllFitnessTestsListOpen,
        fitnessTestTemplates,
        isRecordFitnessTestModalOpen,
        selectedRecordedFitnessTest,
        fitnessTestsConducted,
        activeFitnessTestListFilter,
    ]
    const renderFitnessTab = useMemo(() => {
        return (
            <div className='p-4 relative overflow-x-hidden'>
                <AddFitnessTest 
                    isOpen={isAddFitnessTestModalOpen}
                    handleClose={handleAddFitnessTestFormClose}
                />
                <TakeFitnessTest
                    isOpen={isTakeFitnessTestModalOpen}
                    handleClose={handleTakeFitnessTestModalClose}
                    selectedFitnessTest={selectedFitnessTest || {} as FitnessTestDetailsData}
                    handleRecordFitnessTestModalOpen={handleRecordFitnessTestModalOpen}
                />
                <RecordFitnessTest 
                    isOpen={isRecordFitnessTestModalOpen}
                    handleClose={handleRecordFitnessTestModalClose}
                    selectedConductableTestId={selectedRecordedFitnessTest?.id || 0}
                />
                <div 
                    className={`w-full h-[calc(100vh-180px)] sm:h-[calc(100vh-242px)] flex flex-col transition-all ${isAllFitnessTestsListOpen ? 'opacity-20 cursor-pointer' : 'opacity-100'}`}
                    onClick={() => handleToggleFitnessTestTemplatesListView(false)}
                >
                    <div className={`w-full bg-slate-100 rounded border-2 border-slate-300 p-2 px-8 flex flex-col sm:flex-row items-center `}>
                        <div className='flex-grow hidden sm:block'>
                            <div className='text-lg font-semibold text-gray-700'>Manage and Track Student Fitness Tests</div>
                            <hr className='my-2 border-2 border-slate-200'/>
                            <div className='flex items-start gap-1 mt-1'>
                                <img
                                    src={pointIcon}
                                    className='h-6 w-6'
                                />
                                <div className='text-sm text-gray-600 pt-[1px]'>
                                    <span className='font-semibold'>Define New Fitness Tests:</span> Create customized fitness tests with mandatory fields like height and weight, and add additional metrics as needed
                                </div>
                            </div>
                            <div className='flex items-start gap-1 mt-1'>
                                <img
                                    src={pointIcon}
                                    className='h-6 w-6'
                                />
                                <div className='text-sm text-gray-600 pt-[1px]'>
                                    <span className='font-semibold'>Assign Tests to Students:</span> Select the students you want to include in the test and assign the newly defined fitness test to them.
                                </div>
                            </div>
                            <div className='flex items-start gap-1 mt-1'>
                                <img
                                    src={pointIcon}
                                    className='h-6 w-6'
                                />
                                <div className='text-sm text-gray-600 pt-[1px]'>
                                    <span className='font-semibold'>Record Fitness Data:</span> Use the &apos;Perform Test&apos; option to input student data such as height, weight, reps, etc., at specific timestamps.
                                </div>
                            </div>
                            <div className='flex items-start gap-1 mt-1'>
                                <img
                                    src={pointIcon}
                                    className='h-6 w-6'
                                />
                                <div className='text-sm text-gray-600 pt-[1px]'>
                                    <span className='font-semibold'>Monitor Progress Over Time:</span> Store and review student fitness data to track their progress in future tests.
                                </div>
                            </div>
                        <div className='flex items-start gap-1 mt-1'>
                                <img
                                    src={pointIcon}
                                    className='h-6 w-6'
                                />
                                <div className='text-sm text-gray-600 pt-[1px]'>
                                    <span className='font-semibold'>Flexible Field Selection:</span>  Choose only the fields relevant to your academy&apos;s needs, ensuring each test is tailored for accurate performance tracking.
                                </div>
                            </div>
                        </div>
                        <div className='rounded sm:ml-10 flex flex-col gap-4 w-full sm:w-auto'>
                            <div 
                                className='w-full sm:w-[210px] font-semibold rounded bg-primary text-white h-[48px] flex items-center justify-center gap-4 cursor-pointer px-4 active:scale-95 transition-all'
                                onClick={handleFitnessTestModalOpen}
                            >
                                <img
                                    src={addIcon}
                                    className='h-8 w-8 invert'
                                />
                                Create a Test
                            </div>
                            <div 
                                className='w-full sm:w-[210px] font-semibold rounded bg-primary text-white h-[48px] flex items-center justify-center gap-4 cursor-pointer px-4 active:scale-95 transition-all'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleToggleFitnessTestTemplatesListView(true)
                                }}
                            >
                                <img
                                    src={testIcon}
                                    className='h-10 w-10'
                                />
                                Perform Test
                            </div>
                        </div>
                    </div>
                    <hr className='my-2 border-2 border-slate-200'/>
                    <div className='flex-grow w-full flex flex-col gap-2 overflow-y-auto thin-scrollbar'>
                        <div className='flex justify-center items-center gap-2'>
                            <div 
                                className={`w-28 text-center font-semibold bg-gray-600 text-white rounded-lg py-1 cursor-pointer transition-all ${activeFitnessTestListFilter === 'ALL' ? 'opacity-100' : 'opacity-50'}`}
                                onClick={() => handleActiveFitnessTestListFilterChange('ALL')}
                            >
                                All
                            </div>
                            <div 
                                className={`w-28 text-center font-semibold bg-gray-600 text-white rounded-lg py-1 cursor-pointer transition-all ${activeFitnessTestListFilter === 'COMPLETED' ? 'opacity-100' : 'opacity-50'}`}
                                onClick={() => handleActiveFitnessTestListFilterChange('COMPLETED')}
                            >
                                Completed
                            </div>
                            <div 
                                className={`w-28 text-center font-semibold bg-gray-600 text-white rounded-lg py-1 cursor-pointer transition-all ${activeFitnessTestListFilter === 'INCOMPLETE' ? 'opacity-100' : 'opacity-50'}`}
                                onClick={() => handleActiveFitnessTestListFilterChange('INCOMPLETE')}
                            >
                                Incomplete
                            </div>
                        </div>
                        <div className='flex-grow overflow-y-auto thin-scrollbar'>
                            {fitnessTestsConducted
                                .filter(item => {
                                    if(activeFitnessTestListFilter === 'ALL') {
                                        return true
                                    }else if(activeFitnessTestListFilter === 'COMPLETED') {
                                        return item?.attributes?.isPublished
                                    }else if(activeFitnessTestListFilter === 'INCOMPLETE') {
                                        return !item?.attributes?.isPublished
                                    }
                                })
                                .map(item => (
                                <div key={item?.id} className='p-2 rounded border border-slate-300 grid justify-between items-center grid-cols-5'>
                                    <div className='col-span-2'>
                                        <div className='text-lg font-semibold text-gray-700'>{item?.attributes?.template?.data?.attributes?.name}</div>
                                        <div className='text-sm text-gray-500'>{getFormattedDate(new Date(item?.attributes?.template?.data?.attributes?.createdAt))}</div>
                                    </div>
                                    <div className='col-span-2 text-gray-600'>
                                        {item?.attributes?.participants?.data?.length ?? 0} participants
                                    </div>
                                    <div className='flex justify-end col-span-1'>
                                        {item?.attributes?.isPublished ? (
                                            <div 
                                                onClick={() => handleRecordFitnessTestModalOpen(item)} 
                                                className='text-primary cursor-pointer px-4 py-1 rounded border'
                                            >
                                                View
                                            </div>
                                        ) : (
                                            <div 
                                                onClick={() => handleRecordFitnessTestModalOpen(item)} 
                                                className='text-primary cursor-pointer px-4 py-1 rounded border'
                                            >
                                                Resume
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='flex items-center justify-end bg-[#E4E6E9] h-[50px] border gap-[12px] sm:gap-[30px] sm:pr-[25px] rounded-[0px_0px_3px_3px] border-solid border-[#E4E6E9]'>
                            {/* <div className='text-xs sm:text-[13px] font-normal leading-5 tracking-[0px] text-[#222222]'>
                                {indexOfFirstItem + 1}-{indexOfLastItem} of {total ?? 0} records
                            </div>
                            <div className='text-[13px] font-normal leading-5 tracking-[0px] text-[#222222] flex items-center gap-2.5'>
                                <div>Show</div>
                                <div className='h-8 flex text-xs font-normal leading-5 tracking-[0px] border text-[#AFAFAF] bg-white items-center gap-2 px-[5px] py-0 rounded-[3px] border-solid border-[#AFAFAF]'>
                                    10{' '}
                                    <img
                                        className={style.studentsArrowDropdownIcon}
                                        src={arrowDropdownIcon}
                                        alt="down"
                                    />
                                </div>
                            </div>
                            <div className={style.studentsPaginationContainer}>
                                <img
                                    className={style.studentsPaginationIcon}
                                    src={arrowLeftIcon}
                                    alt="arrowLeftIcon"
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload: currentPage === 1 ? 1 : currentPage - 1,
                                        })
                                    }
                                />
                                <div className="overflow-x-scroll flex items-center sm:gap-[15px] gap-[8px] text-xs sm:text-sm">
                                    {Array.from({
                                        length: endPage - startPage + 1,
                                    }).map((_, index) => (
                                        <div
                                            className={`cursor-pointer ${
                                                startPage + index === currentPage
                                                    ? 'text-black cursor-default '
                                                    : 'text-blue-500'
                                            }`}
                                            key={startPage + index}
                                            onClick={() =>
                                                dispatch({
                                                    type: 'setCurrentPage',
                                                    payload: startPage + index,
                                                })
                                            }
                                        >
                                            {startPage + index}
                                        </div>
                                    ))}
                                </div>
                                <img
                                    className={style.studentsPaginationIcon}
                                    src={arrowRightIcon}
                                    alt="arrowRightIcon"
                                    onClick={() =>
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload:
                                                currentPage + itemsPerPage + 1 > total
                                                    ? currentPage
                                                    : currentPage + 1,
                                        })
                                    }
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className={`absolute p-2 top-0 right-0 w-full sm:w-[70%] h-full bg-slate-200 transition-all rounded-l-lg ${isAllFitnessTestsListOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                    <div className='text-xl font-semibold text-gray-700 flex gap-2 items-center'>
                        <div 
                            className='hover:translate-x-1 transition-all cursor-pointer'
                            onClick={() => handleToggleFitnessTestTemplatesListView(false)}
                        >
                            <img 
                                src={rightArrowIcon}
                                className='h-10 w-10'
                            />
                        </div>
                        <div>Fitness Test Templates</div>
                    </div>
                    <hr className='my-2 border-2 border-slate-400'/>
                    <div className='flex flex-col gap-2'>
                        {fitnessTestTemplates?.map((test,index) => (
                            <div
                                key={test?.id}
                                className='w-fill rounded bg-white p-2 px-4 flex items-center'
                            >
                                <div className='flex-grow'>
                                    <div className='text-lg font-semibold text-gray-600'>{test?.attributes?.name}</div>
                                    <div>Fields added: {test?.attributes?.fieldsRequired?.length}</div>
                                </div>
                                <div
                                    className='text-slate-600 border-2 transition-all hover:bg-slate-600 hover:text-white active:scale-95 border-slate-600 rounded px-2 py-1 font-semibold cursor-pointer'
                                    onClick={() => handleTakeFitnessTestModalOpen(test)}
                                >
                                    Take Test
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    },renderFitnessTabDependencies)


    const renderCertificationsTabDependencies = [
        academies,
        activeAcademySelected,
        activeDisciplineSelected,
        isCertificationsFetching,
        certificationsList,
        isReorderEnabled,
        certificationListCopy,
        isCertificationListShuffled,
        isCertificationFormOpen,
        activeCertificationSelected,
        isCertificationStudentRecordsFetching,
        certificationStudentRecordsList,
        activeCertificationStudents,
        selectedStudentsOfCertificationsRecords,
        isPromoteStudentLevelModalOpen,
        isCertifyNewStudentModalOpen,
        availableNewStudentsToCertify,
        editingCertificateId,
        isCertificationEditFormOpen,
        certificationPageMode,
        isCertificationEventsFetching,
        certificationEventsList,
        isCertificationEventFormOpen,
        allStudentsOFSelectedAcademyAndDiscipline,
        allStudentCertificationRecordsOfDiscipline,
        isCertificationEventEditFormOpen,
        editingCertificationEventId,
        activeLiveCertificationEvent,
        activeStudentInfoFormOpen,
    ]
    const renderCertificationsTab = useMemo(() => {
        const CertificationSection = () => {
            return (
                <>
                    {isCertificationsFetching ? (
                        <div className='w-full flex-grow rounded border overflow-y-auto flex flex-col gap-1'>
                            {[1,2,3,4,5,6,7,8,9,10].map(i => (
                                <div key={i} className='p-2 flex flex-col gap-2 rounded bg-slate-300 animate-pulse w-full'>
                                    <div className='w-1/2 bg-slate-200 animate-pulse h-6 rounded'></div>
                                    <div className='w-1/4 bg-slate-100 animate-pulse h-5 rounded'></div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div 
                            className={`
                                w-full flex-grow rounded flex flex-col gap-1 overflow-y-auto 
                                ${activeCertificationSelected === 0 ? 'thin-scrollbar border' : ''}}
                            `}
                        >
                            {(certificationListCopy.length === 0 && activeCertificationSelected !== -1) && (
                                <div className='w-full h-full flex flex-col gap-2 items-center justify-center'>
                                    <div className='text-2xl font-semibold text-slate-600'>No certifications found!</div>
                                    <div className='text-sm text-slate-500'>Please add by clicking below</div>
                                </div>
                            )}
                            {isReorderEnabled && <div className='absolute top-0 left-0 w-full h-full bg-black opacity-50 z-[9998]'></div>}
                            {certificationListCopy.length > 0 &&
                                <div 
                                    className={`
                                        p-2 mb-2 border-2 rounded flex flex-row items-center gap-2
                                        ${activeCertificationSelected === -1 ? 'border-slate-400' : 'border-slate-200'}
                                        ${activeCertificationSelected === 0 ? '' : 'cursor-pointer'}
                                    `}
                                    onClick={() => {
                                        if (activeCertificationSelected !== 0) {
                                            selectCertification(-1)
                                        }
                                    }}
                                >
                                    <div 
                                        className={`
                                            text-base sm:text-2xl font-semibold text-primary border-2 border-primary h-6 w-6 sm:h-14 sm:w-14 flex items-center justify-center rounded
                                        `}
                                    >
                                        {availableNewStudentsToCertify.length}
                                    </div>
                                    {activeCertificationSelected === 0 && (
                                        <>
                                            <div className='font-semibold text-base sm:text-xl text-slate-700'>Students with No Certification</div>
                                            <div className='flex-grow flex justify-end items-center px-2 gap-4'>
                                                <div 
                                                    className='px-2 py-1 flex items-center sm:gap-2 rounded bg-slate-700 text-white p-1 cursor-pointer'
                                                    onClick={() => selectCertification(-1)}
                                                >
                                                    <div className='text-[0.6rem] sm:text-lg font-semibold sm:ml-2'>Certify</div>
                                                    <img
                                                        src={rightArrowIcon}
                                                        className='sm:flex hidden h-6 w-6'
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            }
                            <DragDropContext onDragEnd={handleReorder}>
                                <Droppable droppableId="certifications" isDropDisabled={!isReorderEnabled}>
                                    {provided => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className='w-full z-[9999]'
                                        >
                                            {certificationListCopy?.map((cert, index) => (
                                                <Draggable 
                                                    key={cert?.id}
                                                    index={index}
                                                    draggableId={String(cert?.id)}
                                                    isDragDisabled={!isReorderEnabled}
                                                >
                                                    {provided => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`
                                                                p-2 mb-2 bg-slate-200 rounded flex flex-row items-center gap-2 border-2  ${isReorderEnabled ? 'cursor-grab' : ''}
                                                                ${activeCertificationSelected === cert?.id ? 'border-slate-400' : 'border-white'}
                                                                ${activeCertificationSelected === 0 ? '' : 'cursor-pointer'}
                                                            `}
                                                            onClick={() => {
                                                                if (activeCertificationSelected !== 0) {
                                                                    selectCertification(cert?.id)
                                                                }
                                                            }}
                                                        >
                                                            <div 
                                                                className={`
                                                                    text-base sm:text-2xl font-semibold bg-primary text-white h-6 w-6 sm:h-14 sm:w-14 flex items-center justify-center rounded
                                                                `}
                                                            >
                                                                {cert?.attributes?.level}
                                                            </div>
                                                            {activeCertificationSelected === 0 && (
                                                                <>
                                                                    <div className='font-semibold text-base sm:text-xl text-slate-700'>{cert?.attributes?.name}</div>
                                                                    <div className='flex-grow flex justify-end items-center px-2 gap-4'>
                                                                        <div 
                                                                            className='flex items-center gap-2 rounded sm:border border-slate-400 p-1 cursor-pointer'
                                                                            onClick={() => handleCertificationEditFormOpen(cert?.id)}
                                                                        >
                                                                            <img
                                                                                src={editIcon}
                                                                                className='h-5 w-5 sm:h-6 sm:w-6'
                                                                            />
                                                                            <div className='sm:flex hidden text-slate-600'>Edit</div>
                                                                        </div>
                                                                        <div 
                                                                            className='px-2 py-1 flex items-center sm:gap-2 rounded bg-slate-700 text-white p-1 cursor-pointer'
                                                                            onClick={() => selectCertification(cert?.id)}
                                                                        >
                                                                            <div className='text-[0.6rem] sm:text-lg font-semibold sm:ml-2'>Assign to students</div>
                                                                            <img
                                                                                src={rightArrowIcon}
                                                                                className='sm:flex hidden h-6 w-6'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}
                    {activeCertificationSelected === 0 && (
                        <div className='flex gap-2 w-full'>
                            {certificationsList?.length > 1 &&
                                <div 
                                    className='w-full mt-2 py-1 bg-primary text-white text-center rounded text-xl font-semibold cursor-pointer active:scale-95 transition-all z-[9999]'
                                    onClick={toggleReorder}
                                >
                                    {isReorderEnabled ? 'Cancel' : 'Reorder'}
                                </div>
                            }
                            {isReorderEnabled ? (
                                <div 
                                    className={`w-full mt-2 py-1 bg-primary text-white text-center rounded text-xl font-semibold cursor-pointer active:scale-95 transition-all
                                        ${isCertificationListShuffled ? 'z-[9999]' : ''}
                                    `}
                                    onClick={handleUpdateCertificationLevels}
                                >
                                    Save changes
                                </div>
                            ) : (
                                <div 
                                    className='w-full mt-2 py-1 bg-primary text-white text-center rounded text-xl font-semibold cursor-pointer active:scale-95 transition-all'
                                    onClick={handleCertificationFormOpen}
                                >
                                    Add New
                                </div>
                            )}
                        </div>
                    )}
                </>
            )
        }

        const CertificationEventSection = () => {
            return (
                <>
                    {isCertificationEventsFetching ? (
                        <div className='w-full flex-grow rounded border overflow-y-auto flex flex-col gap-1'>
                            {[1,2,3,4,5,6,7,8,9,10].map(i => (
                                <div key={i} className='p-2 flex flex-col gap-2 rounded bg-slate-300 animate-pulse w-full'>
                                    <div className='w-1/2 bg-slate-200 animate-pulse h-6 rounded'></div>
                                    <div className='w-1/4 bg-slate-100 animate-pulse h-5 rounded'></div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div 
                            className={`
                                w-full h-full flex-grow rounded flex flex-col gap-1 overflow-y-auto 
                                ${activeLiveCertificationEvent === 0 ? 'thin-scrollbar border' : ''}}
                            `}
                        >
                            {(certificationEventsList.length === 0) && (
                                <div className='w-full h-full flex flex-col gap-2 items-center justify-center'>
                                    <div className='text-2xl font-semibold text-slate-600'>No certification Events found!</div>
                                    <div className='text-sm text-slate-500'>Please add by clicking below</div>
                                </div>
                            )}

                            {certificationEventsList?.map((event, index) => (
                                <div
                                    className={`
                                        p-2 mb-2 bg-slate-200 rounded flex flex-row items-center gap-2 border-2
                                        ${activeLiveCertificationEvent === event?.id ? 'border-slate-400' : 'border-white'}
                                        ${activeLiveCertificationEvent === 0 ? '' : new Date(new Date(event?.attributes?.date).getFullYear(), new Date(event?.attributes?.date).getMonth(), new Date(event?.attributes?.date).getDate()).getTime() === new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime() ? 'cursor-pointer' : 'cursor-not-allowed'}
                                    `}
                                    onClick={() => {
                                        if (activeLiveCertificationEvent !== 0) {
                                            if(new Date(new Date(event?.attributes?.date).getFullYear(), new Date(event?.attributes?.date).getMonth(), new Date(event?.attributes?.date).getDate()).getTime() === new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()) {
                                                handleActiveLiveCertificationEventChange(event?.id)
                                            }
                                        }
                                    }}
                                    key={event?.id}
                                >
                                    <div 
                                        className={`
                                            text-base sm:text-2xl font-semibold bg-primary text-white h-6 w-6 sm:h-14 sm:w-14 flex flex-col items-center justify-center rounded
                                        `}
                                    >
                                        <div>{new Date(event?.attributes?.date).getDate()}</div>
                                        <div className='text-sm font-normal'>{new Date(event?.attributes?.date).toLocaleString('en-US', { month: 'short' })}</div>
                                    </div>
                                    {activeLiveCertificationEvent === 0 && (
                                        <>
                                            <div className='font-semibold text-base sm:text-xl text-slate-700'>
                                                <div>{event?.attributes?.eventName}</div>
                                                <div className='text-sm font-normal text-gray-600'>{getFormattedDate(event?.attributes?.date)}</div>
                                            </div>
                                            {event?.attributes?.isCompleted === false ? (
                                                <div className='flex-grow flex justify-end items-center px-2 gap-4'>
                                                    {new Date(new Date(event?.attributes?.date).getFullYear(), new Date(event?.attributes?.date).getMonth(), new Date(event?.attributes?.date).getDate()) <= new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) && (
                                                        <div 
                                                            className='px-2 py-1 flex items-center sm:gap-2 rounded bg-slate-700 text-white p-1 cursor-pointer'
                                                            onClick={() => handleActiveLiveCertificationEventChange(event?.id)}
                                                        >
                                                            <div className='text-[0.6rem] sm:text-lg font-semibold sm:ml-2'>Event in progress</div>
                                                            <img
                                                                src={rightArrowIcon}
                                                                className='sm:flex hidden h-6 w-6'
                                                            />
                                                        </div>
                                                    )}
                                                    <div 
                                                        className='flex items-center gap-2 rounded sm:border border-slate-400 p-1 cursor-pointer'
                                                        onClick={() => handleCertificationEventEditFormOpen(event?.id)}
                                                    >
                                                        <img
                                                            src={editIcon}
                                                            className='h-5 w-5 sm:h-6 sm:w-6'
                                                        />
                                                        <div className='sm:flex hidden text-slate-600'>Edit</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='flex-grow flex justify-end items-center px-2 gap-4'>
                                                    <div 
                                                        className='flex px-2 items-center gap-2 rounded sm:border border-slate-400 p-1 cursor-pointer'
                                                        onClick={() => alert('View is not available! coming soon')}
                                                    >
                                                        <div className='sm:flex hidden text-slate-600'>View</div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            ))} 
                        </div>
                    )}
                    {activeLiveCertificationEvent === 0 && (
                        <div className='flex gap-2 w-full'>
                            <div 
                                className='w-full mt-2 py-1 bg-primary text-white text-center rounded text-xl font-semibold cursor-pointer active:scale-95 transition-all'
                                onClick={handleCertificationEventFormOpen}
                            >
                                Add
                            </div>
                        </div>
                    )}
                </>
            )
        }

        const CertificationStudentsSection = () => {
            return (
                <div className='h-full'>
                    {activeCertificationSelected === -1 ? (
                        <div className='w-full h-full rounded border overflow-y-auto flex flex-col gap-1'>
                            <div className='p-2 bg-slate-300 w-full flex gap-2 items-center justify-between'>
                                <div className='flex items-center gap-2'>
                                    <div 
                                        className=' text-xs sm:text-lg font-semibold rounded bg-primary text-white py-1 px-2 cursor-pointer transition-all active:scale-95'
                                        onClick={handleAddRemoveAllStudents}
                                    >
                                        {selectedStudentsOfCertificationsRecords.length === availableNewStudentsToCertify?.length ? 'Deselect all' : 'Select all'}
                                    </div>
                                    <div className='text-slate-600 text-sm px-2 py-1 border rounded'>Selected: {selectedStudentsOfCertificationsRecords.length}</div>
                                </div>
                                <div className='flex items-center gap-4'>
                                    {selectedStudentsOfCertificationsRecords.length > 0 && 
                                        <div
                                            className=' text-xs sm:text-base text-white font-semibold px-2 py-1 bg-slate-700 rounded cursor-pointer transition-all active:scale-95' 
                                            onClick={handlePromoteStudentLevelModalOpen}
                                        >
                                            Promote
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='p-1 flex flex-col gap-1 flex-grow overflow-y-auto thin-scrollbar'>
                                {availableNewStudentsToCertify?.length === 0 && (
                                    <div className='w-full h-full flex flex-col gap-2 items-center justify-center'>
                                        <div className='text-slate-600 font-semibold text-2xl drop-shadow-lg'>No Students Available without Certification!</div>
                                    </div>
                                )}
                                {availableNewStudentsToCertify?.map(s => (
                                    <div 
                                        key={s?.id} 
                                        className={`
                                            px-2 py-1 flex items-center gap-2 rounded justify-between border hover:bg-slate-100 transition-all cursor-pointer
                                            ${selectedStudentsOfCertificationsRecords.includes(s?.id) ? 'bg-slate-200' : ''}
                                        `}
                                        onClick={() => handleAddRemoveStudent(s?.id)}
                                    >
                                        <div className='flex items-center gap-2'>
                                            <div className='relative'>
                                                <img 
                                                    src={s?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? defaultProfilePic}
                                                    className='h-10 w-10 rounded'
                                                />
                                                <div className={`
                                                    absolute bottom-0 right-0 rounded border-2 border-slate-700 w-3 h-3
                                                    ${selectedStudentsOfCertificationsRecords.includes(s?.id) ? 'bg-green-500' : ''}
                                                `}></div>
                                            </div>
                                            <div className='font-semibold text-slate-700'>{s?.attributes?.firstName}</div>
                                        </div>
                                        {/* <div className='text-slate-400 text-sm'>
                                            Holder since {getFormattedDate(certificationStudentRecordsList?.find(r => r?.attributes?.student?.data?.id === s?.id)?.attributes?.createdAt ?? '')}
                                        </div> */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (isCertificationStudentRecordsFetching ? (
                                <div className='w-full flex-grow rounded border overflow-y-auto flex flex-col gap-1'>
                                {[1,2,3,4,5,6,7,8,9,10].map(i => (
                                    <div key={i} className='p-2 flex flex-col gap-2 rounded bg-slate-300 animate-pulse w-full'>
                                        <div className='w-1/2 bg-slate-200 animate-pulse h-6 rounded'></div>
                                        <div className='w-1/4 bg-slate-100 animate-pulse h-5 rounded'></div>
                                    </div>
                                ))}
                                </div>
                        ) : (
                            <div className='w-full flex-grow rounded border overflow-y-auto flex flex-col gap-1'>
                                <div className='p-2 bg-slate-300 w-full flex gap-2 items-center justify-between'>
                                    <div className='flex items-center gap-2'>
                                        <div 
                                            className=' text-xs sm:text-lg font-semibold rounded bg-primary text-white py-1 px-2 cursor-pointer transition-all active:scale-95'
                                            onClick={handleAddRemoveAllStudents}
                                        >
                                            {selectedStudentsOfCertificationsRecords.length === certificationStudentRecordsList?.length ? 'Deselect all' : 'Select all'}
                                        </div>
                                        <div className='text-slate-600 text-sm px-2 py-1 border rounded'>Selected: {selectedStudentsOfCertificationsRecords.length}</div>
                                    </div>
                                    <div className='flex items-center gap-4'>
                                        <div
                                            className='text-slate-600 font-semibold text-[0.5rem] sm:text-base px-1 sm:px-4 py-1 border-2 border-slate-700 rounded cursor-pointer transition-all active:scale-95' 
                                            onClick={handleCertifyNewStudentModalOpan}
                                        >
                                            Certify new student
                                        </div>
                                        {selectedStudentsOfCertificationsRecords.length > 0 && 
                                            <div
                                                className=' text-xs sm:text-base text-white font-semibold px-2 py-1 bg-slate-700 rounded cursor-pointer transition-all active:scale-95' 
                                                onClick={handlePromoteStudentLevelModalOpen}
                                            >
                                                Promote
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='p-1 flex flex-col gap-1 flex-grow overflow-y-auto thin-scrollbar'>
                                    {activeCertificationStudents?.length === 0 && (
                                        <div className='w-full h-full flex flex-col gap-2 items-center justify-center'>
                                            <div className='text-slate-600 font-semibold text-2xl drop-shadow-lg'>No Students Found!</div>
                                        </div>
                                    )}
                                    {activeCertificationStudents?.map(s => (
                                        <div 
                                            key={s?.id} 
                                            className={`
                                                px-2 py-1 flex items-center gap-2 rounded justify-between border hover:bg-slate-100 transition-all cursor-pointer
                                                ${selectedStudentsOfCertificationsRecords.includes(s?.id) ? 'bg-slate-200' : ''}
                                            `}
                                            onClick={() => handleAddRemoveStudent(s?.id)}
                                        >
                                            <div className='flex items-center gap-2'>
                                                <div className='relative'>
                                                    <img 
                                                        src={s?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? defaultProfilePic}
                                                        className='h-10 w-10 rounded'
                                                    />
                                                    <div className={`
                                                        absolute bottom-0 right-0 rounded border-2 border-slate-700 w-3 h-3
                                                            ${selectedStudentsOfCertificationsRecords.includes(s?.id) ? 'bg-green-500' : ''}
                                                    `}></div>
                                                </div>
                                                <div className='font-semibold text-slate-700'>{s?.attributes?.firstName}</div>
                                            </div>
                                            <div className='text-slate-400 text-sm'>
                                                Holder since {getFormattedDate(certificationStudentRecordsList?.find(r => r?.attributes?.student?.data?.id === s?.id)?.attributes?.createdAt ?? '')}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    )}
                </div>
            )
        }

        const CertificationEventStudentsSection = () => {
            const selectedEventData = certificationEventsList?.find(e => e?.id === activeLiveCertificationEvent);

            return (
                <div className='h-full'>
                    {isCertificationEventsFetching ? (
                        <div className='w-full flex-grow rounded border overflow-y-auto flex flex-col gap-1'>
                            {[1,2,3,4,5,6,7,8,9,10].map(i => (
                                <div key={i} className='p-2 flex flex-col gap-2 rounded bg-slate-300 animate-pulse w-full'>
                                    <div className='w-1/2 bg-slate-200 animate-pulse h-6 rounded'></div>
                                    <div className='w-1/4 bg-slate-100 animate-pulse h-5 rounded'></div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='w-full h-full rounded border flex flex-col gap-1'>
                            <div className='p-2 bg-slate-300 w-full flex gap-2 items-center justify-between'>
                                <div className='flex items-center gap-2'>
                                    {selectedEventData?.attributes?.isCompleted === true ? (
                                        <div className='text-slate-600 text-sm px-2 py-1 border rounded'>Promoted: {selectedEventData?.attributes?.students?.filter(s => s?.status === 'PROMOTED').length}</div>
                                    ) : (
                                        <div className='text-slate-600 text-sm px-2 py-1 border rounded'>Approved: {selectedEventData?.attributes?.students?.filter(s => s?.status === 'APPROVED').length}</div>
                                    )}
                                    <div className='text-slate-600 text-sm px-2 py-1 border rounded'>Skipped: {selectedEventData?.attributes?.students?.filter(s => s?.status === 'SKIPPED').length}</div>
                                </div>
                                <div className='flex items-center gap-4'>
                                    {/* <div
                                        className='text-slate-600 font-semibold text-[0.5rem] sm:text-base px-1 sm:px-4 py-1 border-2 border-slate-700 rounded cursor-pointer transition-all active:scale-95' 
                                        onClick={handleCertifyNewStudentModalOpan}
                                    >
                                        Certify new student
                                    </div> */}
                                    {selectedEventData?.attributes?.students?.filter(s => s?.status === 'APPROVED' || s?.status === 'SKIPPED').length === selectedEventData?.attributes?.students.length && selectedEventData?.attributes?.isCompleted === false &&
                                        <div
                                            className=' text-xs sm:text-base text-white font-semibold px-2 py-1 bg-slate-700 rounded cursor-pointer transition-all active:scale-95' 
                                            onClick={handlePromoteAllApprovedStudentsOfEvent}
                                        >
                                            Promote All
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='p-1 flex flex-col gap-1 flex-grow overflow-y-auto thin-scrollbar'>
                                {selectedEventData?.attributes?.students?.length === 0 && (
                                    <div className='w-full h-full flex flex-col gap-2 items-center justify-center'>
                                        <div className='text-slate-600 font-semibold text-2xl drop-shadow-lg'>No Students Added!</div>
                                    </div>
                                )}
                                {selectedEventData?.attributes?.students?.map(s => {
                                    const student = allStudentsOFSelectedAcademyAndDiscipline?.find(st => st?.id === s?.studentId);
                                    
                                    return (
                                        <div key={s?.studentId} className={`${activeStudentInfoFormOpen === s?.studentId && 'border-2 border-slate-400 rounded'}`}>
                                            <div 
                                                className={`
                                                    px-2 py-1 flex items-center gap-2 rounded justify-between border hover:bg-slate-100 transition-all cursor-pointer
                                                `}
                                                onClick={() => {
                                                    if(selectedEventData?.attributes?.isCompleted === false){
                                                        handleActiveStudentInfoFormOpen(s?.studentId)
                                                    }
                                                }}
                                            >
                                                <div className='flex items-center gap-2'>
                                                    <div className='relative'>
                                                        <img 
                                                            src={student?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? defaultProfilePic}
                                                            className='h-10 w-10 rounded'
                                                        />
                                                        {/* <img src='/assets/images/green-tick.png' className='h-4 w-4 absolute bottom-0 right-0' /> */}
                                                    </div>
                                                    <div className='font-semibold text-slate-700'>{`${student?.attributes?.firstName} ${student?.attributes?.lastName ?? ''}`}</div>
                                                    {s?.status === 'APPROVED' && <div className='text-[10px] bg-green-300 rounded text-white px-2 py-1'>APPROVED</div>}
                                                    {s?.status === 'SKIPPED' && <div className='text-[10px] bg-slate-300 rounded text-black px-2 py-1'>SKIPPED</div>}
                                                    {s?.status === 'PROMOTED' && <div className='text-[10px] bg-green-300 rounded text-white px-2 py-1'>PROMOTED</div>}
                                                </div>
                                                <div className='text-slate-400 text-sm'>
                                                    <img 
                                                        src={rightArrowIcon}
                                                        className='h-6 w-6 sm:h-10 sm:w-10'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    <CertificationEventStudentInfo 
                        isOpen={activeStudentInfoFormOpen > 0}
                        studentData={allStudentsOFSelectedAcademyAndDiscipline?.find(st => st?.id === activeStudentInfoFormOpen)}
                        handleClose={handleActiveStudentInfoFormClose}
                        studentsPresentCertification={allStudentCertificationRecordsOfDiscipline?.find(c => c?.attributes?.student?.data?.id === activeStudentInfoFormOpen)}
                        certificationsList={certificationsList}
                        selectedEventData={selectedEventData}
                    />
                </div>
            )
        }
        
        
        return (
            <>
                <div className='p-2'>
                    <div className="flex items-center gap-2 h-8">
                        {activeAcademySelected !== 0 &&
                            <div 
                                className="bg-gray-400 px-2 h-8 rounded-md relative flex items-center justify-center z-30 cursor-pointer hover:scale-110 transition-all"
                                onClick={() => selectAcademyInCertification(0)}
                            >
                                <div className="absolute top-[calc(50%-12px)] right-[-8px] h-6 w-6 bg-gray-400 rotate-45 rounded"></div>
                                <span className='z-10 text-xs sm:text-base'>
                                    {academies?.find(academy => academy?.id === activeAcademySelected)?.attributes?.name}
                                </span>
                            </div>
                        }
                        {activeDisciplineSelected !== 0 &&
                            <div 
                                className="bg-gray-200 px-2 h-8 rounded-md relative flex items-center justify-center -translate-x-4 z-20 cursor-pointer hover:scale-110 transition-all"
                                onClick={() => selectDisciplineInCertification(0)}
                            >
                                <div className="absolute top-[calc(50%-12px)] right-[-8px] h-6 w-6 bg-gray-200 rotate-45 rounded"></div>
                                <span className='z-20 pl-4  text-xs sm:text-base'>
                                    {academies?.find(academy => academy?.id === activeAcademySelected)?.attributes?.selectedDisciplines?.data
                                        ?.find(discipline => discipline?.id === activeDisciplineSelected)?.attributes?.name}
                                </span>
                            </div>
                        }
                        {activeCertificationSelected !== 0 &&
                            <div 
                                className="bg-gray-400 px-2 h-8 rounded-md relative flex items-center justify-center z-10 -translate-x-8 cursor-pointer hover:scale-110 transition-all"
                                onClick={() => selectCertification(0)}
                            >
                                <div className="absolute top-[calc(50%-12px)] right-[-8px] h-6 w-6 bg-gray-400 rotate-45 rounded"></div>
                                <span className='z-30 pl-4  text-xs sm:text-base'>
                                    {certificationsList?.find(cert => cert?.id === activeCertificationSelected)?.attributes?.name ?? 'Students with no certification'}
                                </span>
                            </div>
                        }
                        {activeLiveCertificationEvent !== 0 &&
                            <div 
                                className="bg-gray-400 px-2 h-8 rounded-md relative flex items-center justify-center z-10 -translate-x-8 cursor-pointer hover:scale-110 transition-all"
                                onClick={() => handleActiveLiveCertificationEventChange(0)}
                            >
                                <div className="absolute top-[calc(50%-12px)] right-[-8px] h-6 w-6 bg-gray-400 rotate-45 rounded"></div>
                                <span className='z-30 pl-4  text-xs sm:text-base'>
                                    {certificationEventsList?.find(event => event?.id === activeLiveCertificationEvent)?.attributes?.eventName}
                                </span>
                            </div>
                        }
                    </div>
                </div>
                <div className='flex w-full h-[calc(100%-48px)]'>
                    {/* Academy Selection */}
                    <div className={`
                        ${activeAcademySelected !==0 ? 'hidden':"flex"} sm:flex flex-col gap-2 p-2 h-full overflow-y-auto transition-all
                        ${(activeAcademySelected === 0 || activeDisciplineSelected === 0) ? 'w-full sm:w-[50%] thin-scrollbar' : 'sm:w-[88px] min-w-[88px]'}
                    `}>
                        {academies.map(academy => (
                            <div 
                                key={academy?.id} 
                                className={`
                                    rounded bg-slate-200 p-2 flex items-center gap-2 cursor-pointer transition-all border-2
                                    ${activeAcademySelected === academy?.id ? 'border-slate-400' : 'border-white'}
                                `}
                                onClick={() => selectAcademyInCertification(academy?.id)}
                            >
                                <div>
                                    <img 
                                        src={AcademyIcon}
                                        className='h-10 w-10 sm:h-14 sm:w-14 rounded bg-primary p-2'
                                    />
                                </div>
                                {(activeAcademySelected === 0 || activeDisciplineSelected === 0) && (
                                    <>
                                        <div>
                                            <div className='text-sm sm:text-lg font-semibold text-slate-800'>{academy?.attributes?.name}</div>
                                            <div className='text-xs sm:text-sm text-slate-500'>{academy?.attributes?.selectedDisciplines?.data?.map(item => item?.attributes?.name).join(', ')}</div>
                                        </div>
                                        <div className='flex-grow flex items-center justify-end'>
                                            <img 
                                                src={rightArrowIcon}
                                                className='h-6 w-6 sm:h-10 sm:w-10'
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className='hidden sm:block w-2 bg-slate-200 h-full'></div>

                    {/* Discipline Selection */}
                    <div 
                        className={`
                            sm:flex ${activeAcademySelected===0 || activeDisciplineSelected !==0 ? "hidden" : "flex"} flex-col gap-2 p-2 h-full overflow-y-auto  transition-all 
                            ${(activeAcademySelected === 0 || activeDisciplineSelected === 0) ? 'sm:w-[50%] w-full thin-scrollbar' : 'w-[88px] min-w-[88px]'}
                        `}
                    >
                        {academies?.find(academy => academy?.id === activeAcademySelected)?.attributes?.selectedDisciplines?.data?.map(discipline => (
                            <div 
                                key={discipline?.id}
                                className={`
                                    rounded bg-slate-200 p-2 flex items-center gap-2 cursor-pointer border-2
                                    ${activeDisciplineSelected === discipline?.id ? 'border-slate-400' : 'border-white'}
                                `}
                                onClick={() => selectDisciplineInCertification(discipline?.id)}
                            >
                                <div className='text-base sm:text-2xl font-semibold bg-primary text-white h-10 w-10 sm:h-14 sm:w-14 flex items-center justify-center rounded'>
                                    {discipline?.attributes?.name[0].toUpperCase()}
                                </div>
                                {(activeAcademySelected === 0 || activeDisciplineSelected === 0) && (
                                    <div className='sm:text-base text-sm'>{discipline?.attributes?.name}</div>
                                )}
                            </div>
                        ))}
                        {activeAcademySelected === 0 && (
                            <div className='h-full w-full flex p-2'>
                                <div className='font-semibold text-slate-400 p-4 rounded border overflow-y-auto'>
                                    <div>
                                        <div className='font-semibold text-gray-700'>Select Academy:</div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            Begin by selecting the academy for which you want to assign certificates.
                                        </div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            The list of available disciplines will be displayed for the selected academy.
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='font-semibold text-gray-700'>Select a Discipline:</div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            After choosing the academy, select a discipline from the list.
                                        </div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            Once a discipline is selected, the associated certifications and their levels will be visible.
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='font-semibold text-gray-700'>View and Manage Certifications:</div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            The certifications list for the selected discipline will show up.
                                        </div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            Each certification is categorized by its level (in numbers starting from 0).
                                        </div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            You have the option to add new certifications or reorder the levels of existing certifications based on your academy&#39;s progression structure.
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='font-semibold text-gray-700'>View Students Assigned to a Certification:</div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            Once you select a specific certification, the list of students who are already assigned to that certification level will be displayed.
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='font-semibold text-gray-700'>Promote Students to Higher Levels:</div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            You can select one or more students from the list who already have this certification.
                                        </div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            Promote them to a higher certification level within the discipline.
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='font-semibold text-gray-700'>Assign Certificates to New Students:</div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            If a student does not have any certification for the selected discipline yet, you can assign them the appropriate starting level certification.
                                        </div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            This allows you to add new students to the certification track for the selected discipline.
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='font-semibold text-gray-700'>Finalize and Confirm:</div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            Once you&#39;ve selected the students and the certification level (either for promotion or for new students), you can confirm the assignment.
                                        </div>
                                        <div className='text-sm text-slate-500 flex items-center gap-2 ml-4'>
                                            <img
                                                src={pointIcon}
                                                className='h-6 w-6'
                                            />
                                            The system will update the student&#39;s certification status accordingly.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='hidden sm:block w-2 bg-slate-200 h-full'></div>

                    {/* Certification */}
                    <div className={`
                        transition-all h-full flex flex-col p-2
                        ${activeDisciplineSelected === 0 ? 'opacity-0 hidden' : 'opacity-100'}
                        ${(activeCertificationSelected === 0 && activeLiveCertificationEvent === 0) ? 'w-full' : 'hidden sm:block sm:w-[88px] sm:min-w-[88px] sm:max-w-[88px]'}
                    `}>
                        {certificationPageMode === null ? (
                            <div className='w-full h-full flex items-center justify-center gap-12'>
                                <div 
                                    onClick={() => setCertificationPageMode('DEFAULT')}
                                    className='gap-4 transition-all hover:scale-110 bg-slate-50 cursor-pointer text-2xl font-semibold rounded border-2 border-slate-200 w-[30%] flex flex-col items-center justify-center py-4'
                                >
                                    <img 
                                        src='/assets/images/certificate.png'
                                        className='h-16 w-16'
                                    />
                                    Certifications
                                </div>
                                <div 
                                    onClick={() => setCertificationPageMode('EVENT')}
                                    className='gap-4 transition-all hover:scale-110 bg-slate-50 cursor-pointer text-2xl font-semibold rounded border-2 border-slate-200 w-[30%] flex flex-col items-center justify-center py-4'
                                >
                                    <img 
                                        src='/assets/images/event.png'
                                        className='h-16 w-16'
                                    />
                                    Certification Events
                                </div>
                            </div>
                        ) : certificationPageMode === 'EVENT' ? (
                            <CertificationEventSection />
                        ) : (
                            <CertificationSection />
                        )}
                    </div>
                    {(activeCertificationSelected !== 0 || activeLiveCertificationEvent !== 0) && <div className='hidden sm:block w-2 bg-slate-200 h-full'></div>}

                    {/* Students list */}
                    <div
                        className={`
                            transition-all h-full w-full flex-col p-2
                            ${(activeCertificationSelected === 0 && activeLiveCertificationEvent === 0) ? 'hidden' : 'flex'}
                        `}
                    >
                        {certificationPageMode === 'DEFAULT' ? (
                            <CertificationStudentsSection />
                        ) : (
                            <CertificationEventStudentsSection />
                        )}
                    </div>

                </div>
                <AddCertifications
                    isOpen={isCertificationFormOpen || isCertificationEditFormOpen}
                    handleClose={isCertificationEditFormOpen ? handleCertificationEditFormClose : handleCertificationFormClose}
                    isEditing={isCertificationEditFormOpen}
                    editingCertificationData={certificationsList?.find(cert => cert?.id === editingCertificateId) || null}
                    certificationsList={certificationsList}
                    academySelected={academies?.find(academy => academy?.id === activeAcademySelected) || {} as AcademyData}
                    disciplineSelected={academies?.find(academy => academy?.id === activeAcademySelected)?.attributes?.selectedDisciplines?.data
                        ?.find(discipline => discipline?.id === activeDisciplineSelected) || {} as DisciplineData}
                />
                <AddCertificationEvent 
                    isOpen={isCertificationEventFormOpen || isCertificationEventEditFormOpen}
                    isEditing={isCertificationEventEditFormOpen}
                    editingEventData={certificationEventsList?.find(event => event?.id === editingCertificationEventId) || null}
                    handleClose={isCertificationEventEditFormOpen ? handleCertificationEventEditFormClose : handleCertificationEventFormClose}
                    activeAcademySelected={activeAcademySelected}
                    allStudentCertificationRecordsOfDiscipline={allStudentCertificationRecordsOfDiscipline}
                    activeDisciplineSelected={activeDisciplineSelected}
                    allStudentsOFSelectedAcademyAndDiscipline={allStudentsOFSelectedAcademyAndDiscipline}
                    certificationsList={certificationsList}
                />
                <CertificatePromotion 
                    isOpen={isPromoteStudentLevelModalOpen}
                    handleClose={handlePromoteStudentLevelModalClose}
                    selectedStudents={selectedStudentsOfCertificationsRecords}
                    certificationsList={certificationsList}
                    certificationStudentRecordsList={certificationStudentRecordsList}
                    selectedCertificationData={certificationsList?.find(cert => cert?.id === activeCertificationSelected) || {} as CertificationData}
                />
                <CertifyNewStudent 
                    isOpen={isCertifyNewStudentModalOpen}
                    handleClose={handleCertifyNewStudentModalClose}
                    availableNewStudentsToCertify={availableNewStudentsToCertify}
                    selectedCertificationData={certificationsList?.find(cert => cert?.id === activeCertificationSelected) || {} as CertificationData}
                />
            </>
        )
    },[renderCertificationsTabDependencies])

    const renderMainContent = useMemo(() => {
        return (
            <div className="flex-1">
                <div className={state.openTab === 1 ? style.contentContainerMain : 'hidden'}>
                    {renderFitnessTab}
                </div>

                <div className={state.openTab === 2 ? style.contentContainerMain : 'hidden'}>
                    {renderHomeAssignmentsTab}
                </div>

                <div className={state.openTab === 3 ? style.contentContainerMain : 'hidden'}>
                    {renderCertificationsTab}
                </div>
            </div>
        );
    }, [
        state.openTab,
        AIModules,
        regularAssignment,
        regularAssignments,
        addNewTechnique,
        isView,
        selectedAcademyId,
        url,
        file,
        techniqueName,
        isModalOpen,
        isRegularAssignmentViewing,
        rows,
        data,
        isRegularAssignmentEditing,
        timestamp,
        regularAssignmentFormData,
        addRegularAssignment,
        isEdit,
        filteredKeyPointsData,
        joinOneValue,
        joinTwoValue,
        weightageValue,
        currentTimestamp,
        selectedClassesId,
        selectedTechniqueToView,
        isLoading,
        isTechniqueEditing,
        filteredKeyPointsData,
        joinOneSearchTerm,
        joinTwoSearchTerm,
        isJoinTwoSearchInputOnFocus,
        isJoinOneSearchInputOnFocus,
        selectedId,
        studentQuery,
        isFocused,
        studentsData,
        selectedStudents,
        description,
        setdescription,
        ...renderFitnessTabDependencies,
        ...renderCertificationsTabDependencies,
    ]);

    return (
        <div>
            {(isLoading || isFitnessTestAdding)  && <FullScreenLoader />}
            <NavMenu>
                {userState?.user?.role?.type === Roles.AUTHENTICATED_USER ? (
                    <div className={style.container}>
                        {/* Tabs */}
                        <ul
                            className={style.tabListContainer}
                            role="tablist"
                        >
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${state.openTab == 1 
                                        ? 'bg-white shadow-2xl border-2 border-gray-500'
                                        : 'bg-transparent border-2 border-gray-300 scale-95'
                                    }`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload: 1,
                                        });
                                        setOpenTab(1);
                                    }}
                                    data-toggle="tab"
                                    href="#link1"
                                    role="tablist"
                                >
                                    Fitness Test
                                </a>
                            </li>
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${state.openTab == 2 
                                        ? 'bg-white shadow-2xl border-2 border-gray-500'
                                        : 'bg-transparent border-2 border-gray-300 scale-95'
                                    }`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload: 1,
                                        });
                                        setOpenTab(2);
                                    }}
                                    data-toggle="tab"
                                    href="#link2"
                                    role="tablist"
                                >
                                    Home Assignments
                                </a>
                            </li>
                            <li
                                className={
                                    style.tabListItem +
                                    ` ${state.openTab == 3 
                                        ? 'bg-white shadow-2xl border-2 border-gray-500'
                                        : 'bg-transparent border-2 border-gray-300 scale-95'
                                    }`
                                }
                            >
                                <a
                                    className={style.tabListItemLink}
                                    onClick={e => {
                                        e.preventDefault();
                                        dispatch({
                                            type: 'setCurrentPage',
                                            payload: 1,
                                        });
                                        setOpenTab(3);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    Certifications
                                </a>
                            </li>
                        </ul>

                        {/* Render Main Content */}
                        <div className={style.mainContentContainer}>{renderMainContent}</div>
                    </div>
                ) : (
                    <AccessDeniedPage />
                )}
            </NavMenu>
            <PopupModal
                background="white"
                isOpen={isEditKeyPointModalOpen}
                handleModalClose={handleEditKeyPointModalClose}
                title="Edit Keypoint"
            >
                <EditTechniqueKeyPointForm
                    data={editingKeyPointData}
                    handleEditKeyPointModalInputChange={handleEditKeyPointModalInputChange}
                    handleSaveKeyPointUpdatedData={handleSaveKeyPointUpdatedData}
                    handleDeleteKeyPointUpdatedData={handleDeleteKeyPointUpdatedData}
                />
            </PopupModal>
        </div>
    );
};

export default ProgressTracker;
